import React from 'react';
import { LoadingPage, Page } from 'components/core';
import { FormProvider, useForm } from 'react-hook-form';
import TitleBar from 'screens/Components/TitleBar';
import { usePTD } from 'context/PTD/PTDContext';
import { Link } from 'react-router-dom';
import ListView from 'screens/PremiumTradeDirectory/components/views/ListView';

function PremiumTradeDirectoriesOverview() {
	const pageTitle = 'Vendors Lists Overview';

	const {
		getAllPTDs,
		isInitializingPTD,
		getCompanyProfile,
		getPTDMyLists,
		getPTDComanagedLists,
	} = usePTD();
	const companyProfile: OrganizationDTO | null = getCompanyProfile();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const PTDs = getAllPTDs();
	const PTDMyLists: PremiumTradeDirectory[] = getPTDMyLists();
	const PTDComanagedLists: PremiumTradeDirectory[] = getPTDComanagedLists();

	const methods = useForm<any>({ reValidateMode: 'onChange' });

	if (isInitializingPTD === true || isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<FormProvider {...methods}>
			<Page title={pageTitle}>
				<div className="d-flex">
					<div className="d-flex flex-column w-100">
						<TitleBar pageTitle={pageTitle} />
					</div>
				</div>
				<div className="container-fluid mb-2 px-5 py-3">
					<Link to={'/vna'} target="_blank">
						Go to the Vendor Network Analysis
					</Link>

					{PTDs.length > 0 &&
						PTDs.map((directory, key) => (
							<div className="card shadow p-3 my-3 rounded" key={`PTD-${key}`}>
								<Link to={`/premium-trade-directories/${directory.id}`}>
									<div className="card-header d-flex align-items-center">
										<h4 className="mb-0">{directory.name}</h4>
									</div>
								</Link>
								<hr />
								<div className="card-body">
									<div className="container-fluid overflow-hidden">
										<div className="row my-4">
											<div className="col-12">
												<ListView
													listName={`${companyProfile?.organizationName} Lists`}
													listKey={'myList'}
													list={PTDMyLists.filter(
														(l) => l.containsVendorType === directory.vendorType
													)}
													idPTD={directory.id}
													vendorType={directory.vendorType as any}
													onIsLoading={(isLoading) => setIsLoading(isLoading)}
												/>
											</div>
										</div>
										{PTDComanagedLists && (
											<div className="row my-5">
												<div className="col-12">
													<ListView
														listName="Comanaged Lists"
														listKey={'comanagedList'}
														list={PTDComanagedLists.filter(
															(l) => l.containsVendorType === directory.vendorType
														)}
														idPTD={directory.id}
														vendorType={directory.vendorType as any}
														onIsLoading={(isLoading) => setIsLoading(isLoading)}
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						))}
				</div>
			</Page>
		</FormProvider>
	);
}

export default PremiumTradeDirectoriesOverview;
