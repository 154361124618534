export function generateCombinations(specs: { [x: string]: string[] }, picklistItems: PickListItem[]) {
	const keys = Object.keys(specs);
  	const combinations: { [x: string]: string }[] = [];

  	Object.entries(specs).forEach(([key, value]) => {
		const newCombinations: { [x: string]: string }[] = [];
		if (Array.isArray(value)) {
			value.forEach((v) => {
				if (combinations.length === 0) {
					newCombinations.push({ [key]: v });
				} else {
					combinations.forEach((c) => {
						newCombinations.push({ ...c, [key]: v });
					});
				}
			});
		} else {
			if (combinations.length === 0) {
				newCombinations.push({ [key]: value });
			} else {
				combinations.forEach((c) => {
					newCombinations.push({ ...c, [key]: value });
				});
			}
		}

		combinations.length = 0;
		combinations.push(...newCombinations);
  	});

	return combinations.map(combination => {
		return keys.filter(key => combination[key] !== 'any').map(key => {
			const item = combination[key];
		  return {
			  code: key,
			  pickListItemId: picklistItems.find(
				  (v) => v.pickListType?.code === key && v.code === item
			  )?.id,
			  specId: picklistItems.find((v) => v.code === key)?.id,
		  };
		});
	});
}