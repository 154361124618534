// Add this line to make the file a module
export enum GAUGE_COLORS {
	default = '#006361',
	red = '#e30e0e',
	orange = '#f15d1e',
	yellow = '#f3ba27'
};

export enum GRAPH_COLORS {
	location = '#0c419c',
	vendor = '#209321',
	product = '#f1641e',
	parent = '#006361',
	bridge = 'red',
};