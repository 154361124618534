import React from 'react';
import { FormGroup, Select } from 'components/core';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Text } from 'screens/ProductCatalog/components/columns';
import classNames from 'classnames';

interface TableColumnUserDefinedStatusProps {
	vendorFormMethods: UseFormReturn<any, object>;
	row: any;
	editTable: VendorTable | ProductTable | null
}

function TableColumnUserDefinedStatus({vendorFormMethods, row, editTable}: TableColumnUserDefinedStatusProps) {
	//Status
	const statusOptions: { value: string; label: string }[] = [
		{ value: 'Approved', label: 'Approved' },
		{ value: 'Evaluated', label: 'Evaluated' },
		{ value: 'UnderReview', label: 'Under Review' },
		{ value: 'OnHold', label: 'On Hold' },
		{ value: 'Delete', label: 'Delete' },
		{ value: 'Review', label: 'Review' },
	];

	if (!!editTable) {
		if (editTable?.rowId === row.index) {
			return (
				<Controller
					key={'status'}
					control={vendorFormMethods.control}
					defaultValue={row.value || 'Approved'}
					rules={{
						required: 'This is a required field.',
					}}
					name="specs.userDefinedStatus"
					render={({ field, fieldState }) => (
						<FormGroup
							field={field}
							fieldState={fieldState}
							className="m-0 p-0"
						>
							<Select
								id="status"
								name={field.name}
								value={field.value}
								options={statusOptions}
								onChange={field.onChange}
								onMenuOpen={vendorFormMethods.clearErrors}
								className={classNames('p-1 pt-1 fs-14 table-select',  !!fieldState.error?.message ? 'custom-input-error' : 'custom-input')}
								styles={{
									control: (provided, state) => ({
										...provided,
										backgroundColor: fieldState.error ? '#FFE6DC' : '#FFFFFF',
										borderRadius: '4px',
										boxShadow: state.isFocused ? 'inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(0, 99, 97, 0.25);' : 'none',
										borderColor: state.isFocused ? '#006361' : fieldState.error ? '#F15D1E' : '#BDB3AD',
										'&:hover': {
										  borderColor: state.isFocused ? '#006361' : fieldState.error ? '#F15D1E' : '#BDB3AD',
										},
									}),
									placeholder: (provided) => ({
										...provided,
										color: fieldState.error ? '#F15D1E' : '#BDB3AD',
									}),
								}}
							/>
						</FormGroup>
					)}
				/>
			);
		}
	}
	if (row.value === undefined || row.value.length === 0) {
		return <Text></Text>;
	}

	const option = statusOptions.find(
		(status) => status.value === row.value
	);
	return (
		<Text tooltip={option?.label}>
			{
				option?.label
			}
		</Text>
	);
}

export default TableColumnUserDefinedStatus;
