/* eslint-disable max-len */
import React from 'react';
import { ReactComponent as IconArrowLeft } from 'assets/icons/icon-arrow-left.svg';
import LogoutButton from 'components/scopematch/LogoutButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserOrganizations } from 'context/UserOrganizations/UserOrganizationsContext';
import { IconCompanyProfile, IconThreeDotsVertical } from 'assets/icons';
import { Button, LoadingPage, Select } from 'components/core';
import { ReactComponent as IconSettings } from 'assets/icons/icon-settings.svg';
import AuthContext from 'context/Auth/AuthContext';
import classNames from 'classnames';

const SettingsButton: React.FC = () => {
	const navigate = useNavigate();

	const handleNavigateToSettings = () => {
		navigate('/settings/manage-subscriptions');
	};

	return (
		<Button className="btn-settings" onClick={handleNavigateToSettings}>
			<span>Settings</span>
			<IconSettings className="ms-2" />
		</Button>
	);
};

const ProfileMenu: React.FC = () => {
	const [showMenu, setShowMenu] = React.useState(false);

	const handleShowMenu = () => {
		setShowMenu(v => !v);
	};

	return (
		<div className="dropdown">
			<Button className="btn-icon" id="dropdownMenuButton"
				aria-haspopup="true" aria-expanded={showMenu} onClick={handleShowMenu}>
				<IconThreeDotsVertical className="text-primary" />
			</Button>
			{showMenu &&
			<div className={classNames('dropdown-menu end-0', showMenu ? 'show' : '')}
				aria-labelledby="dropdownMenuButton">
				<div className="dropdown-item">
					<SettingsButton />
				</div>
				<div className="dropdown-item">
					<LogoutButton />
				</div>
			</div>}
		</div>
	);
};

interface IProps {
	pageTitle?: string;
	backButton?: boolean;
	onClick?: () => void;
}

const EXCLUDED_PATHS = ['myList', 'comanagedList'];

const TitleBar = (props: IProps) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { organizations, loading, error } = useUserOrganizations();
	const { currentUser, refreshUser } = React.useContext(AuthContext);

	const [isExcludedPath, setIsExcludedPath] = React.useState<boolean>();

	React.useEffect(() => {
		// Check if any excluded path exists in the current location pathname
		setIsExcludedPath(
			EXCLUDED_PATHS.some((path) => location.pathname.includes(path))
		);
	}, [location.pathname]);

	const goBack = () => {
		if (props.onClick) {
			props.onClick();
		} else {
			navigate(-1);
		}
	};

	const handleSelectOrg = (orgId: any) => {
		refreshUser(orgId);
	};

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<div className="container-fluid py-3 shadow px-5 mb-3 titlebar">
			<div className="d-flex">
				<h2 className="mb-0 d-flex align-items-center">
					{props.backButton === true && (
						<button onClick={goBack} className="btn btn-icon d-flex p-0 me-2">
							<IconArrowLeft />
						</button>
					)}
					{props.pageTitle}
				</h2>
				<div className="ms-auto d-flex align-items-center">
					{error ??
					(organizations && organizations.length > 1 && !isExcludedPath) ? (
							<React.Fragment>
								<IconCompanyProfile className="me-1 mb-1" />
								<Select
									value={currentUser.organizationId}
									options={organizations?.map((o) => ({
										value: o.organizationId,
										label: o.organizationName,
									}))}
									className="me-1"
									styles={{
										control: (provided) => ({
											...provided,
											width: '300px',
										}),
									}}
									onChange={handleSelectOrg}
								/>
							</React.Fragment>
						) : (
							<h5 className="mb-0 fw-normal me-2">
								<IconCompanyProfile className="me-1 mb-1" />
								{organizations?.find(
									(o) => o.organizationId === currentUser.organizationId
								)?.organizationName || ''}
							</h5>
						)}
					<ProfileMenu />
				</div>
			</div>
		</div>
	);
};

export default TitleBar;