import { ModalOverlay } from 'components/core';
import React from 'react';


interface ScrappedDTOModalProps {
	isOpen: boolean;
	scrappedDTO: ScrappedCompanyDTO | null;
	onRequestClose: () => void;
	onRequestSupport: () => void;
	onScrappedDTOConfirm: (scrappedDTO: ScrappedCompanyDTO) => Promise<boolean>;
}


function ScrappedDTOModal({ isOpen, scrappedDTO, onRequestClose, onRequestSupport, onScrappedDTOConfirm}: ScrappedDTOModalProps) {
	const isMounted = React.useRef(true);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false; // cleanup on unmount
		};
	}, []);

	async function handleScrappedDTOConfirm() {
		if (scrappedDTO !== null) {
			setIsLoading(true);

			await onScrappedDTOConfirm(scrappedDTO);

			if (isMounted.current) {
				setIsLoading(false);
				onRequestClose();
			}
		}
	}


	return (
		<div>
			<ModalOverlay
				isOpen={isOpen}
				modalSize="modal-dialog-centered"
				onRequestClose={onRequestClose}
				confirmButtonChildren="Accept"
				cancelButtonChildren={scrappedDTO === null ? 'Contact Support' : 'Cancel'}
				confirmButtonAction={handleScrappedDTOConfirm}
				cancelButtonAction={scrappedDTO === null ? onRequestSupport : onRequestClose}
				confirmButtonDisabled={scrappedDTO === null}
				confirmButtonFirst={true}
				isLoading={isLoading}
				hideCloseButton={false}
			>
				<p>
					{scrappedDTO !== null ?
						<ul>
							{scrappedDTO.name && <li><span className="fw-bold">Company Name:</span> {scrappedDTO.name}</li>}
							{scrappedDTO.companyURL && <li><span className="fw-bold">Company Website:</span> {scrappedDTO.companyURL}</li>}
							{scrappedDTO.headOffice && <li><span className="fw-bold">Company Head Office:</span> {scrappedDTO.headOffice}</li>}
							{scrappedDTO.otherLocations?.length > 0 && <li><span className="fw-bold">Other Locations</span> {
								<ul>
									{scrappedDTO.otherLocations.map((l, key) => (
										<li key={key}>{l}</li>
									))}
								</ul>
							}</li>}
							{scrappedDTO.contacts?.length > 0 && <li><span className="fw-bold">Company Contacts:</span>
								<ul>
									{scrappedDTO.contacts.map((c, key) => (
										<li key={key}>{c}</li>
									))}
								</ul>
							</li>}
							{scrappedDTO.insuranceRange && <li>
								<span className="fw-bold">Company Insurance Range:</span> {scrappedDTO.insuranceRange}
							</li>}
							{scrappedDTO.industries?.length > 0 && <li><span className="fw-bold">Company Industries:</span>
								<ul>
									{scrappedDTO.industries.map((i, key) => (
										<li key={key}>{i}</li>
									))}
								</ul>
							</li>}
							{scrappedDTO.sectors?.length > 0 && <li><span className="fw-bold">Company Sectors:</span>
								<ul>
									{scrappedDTO.sectors.map((i, key) => (
										<li key={key}>{i}</li>
									))}
								</ul>
							</li>}
							{scrappedDTO.manufacturingLocations?.length > 0 && <li><span className="fw-bold">Company Manufacturing Locations:</span>
								<ul>
									{scrappedDTO.manufacturingLocations.map((ml, key) => (
										<li key={key}>{ml}</li>
									))}
								</ul>
							</li>}
						</ul>
						: 'We ran into a technical issue, please try again later or contact support.'}
				</p>
			</ModalOverlay>
		</div>
	);
}

export default ScrappedDTOModal;
