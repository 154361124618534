import { Page } from 'components/core';
import React from 'react';
import TitleBar from 'screens/Components/TitleBar';
import AccountBilling from 'screens/Settings/AccountBilling';


interface SettingsProps {}

enum SettingsState {
	ACCOUNT = 'account-billing',
	USERS = 'manage-users'
}


function Settings({}: SettingsProps) {
	const pageTitle = 'Settings';
	const [tab, setTab] = React.useState<SettingsState>(
		SettingsState.ACCOUNT
	);

	const handleLayoutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTab(e.target.value as SettingsState);
	};

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar pageTitle={pageTitle} />
				</div>
			</div>
			<div className="container-fluid mb-2 px-5 py-3">
				<div className="row">
					<div className="col-8">
						<div
							className="btn-group h-100"
							role="group"
							aria-label="Basic radio toggle button group"
						>
							<input
								type="radio"
								className="btn-check"
								name="btnradio"
								id="btnradio1"
								autoComplete="off"
								value={SettingsState.ACCOUNT}
								checked={tab === SettingsState.ACCOUNT}
								onChange={handleLayoutChange}
							/>
							<label className="btn btn-outline-primary h-100 btn-content" htmlFor="btnradio1">
								Account and Billing
							</label>

							{/* <input
								type="radio"
								className="btn-check"
								name="btnradio"
								id="btnradio3"
								autoComplete="off"
								value={SettingsState.USERS}
								checked={tab === SettingsState.USERS}
								onChange={handleLayoutChange}
							/>
							<label className="btn btn-outline-primary h-100 btn-content" htmlFor="btnradio3">
								Manage Users
							</label> */}
						</div>
					</div>
					{
						tab === SettingsState.ACCOUNT ? (
							<AccountBilling />
						) : ('')
					}
				</div>
			</div>
		</Page>
	);
}

export default Settings;
