import { ModalOverlay } from 'components/core';
import { useSubscriptionContext } from 'context/Subscriptions/SubscriptionsContext';
import React from 'react';

// If using TypeScript, add the following snippet to your file as well.
declare global {
	namespace JSX {
	  interface IntrinsicElements {
		'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
	  }
	}
}

interface ChangePlanModalProps {
	isOpen: boolean;
	onRequestClose: () => void;
}


function ChangeSubscriptionModal({ isOpen, onRequestClose }: ChangePlanModalProps) {
	const { customer, loadingCustomer } = useSubscriptionContext();

	React.useEffect(() => {
		// Check if the script is already added
		const scriptId = 'stripe-pricing-table-script';
		if (!document.getElementById(scriptId)) {
		  const script = document.createElement('script');
		  script.id = scriptId;
		  script.src = 'https://js.stripe.com/v3/pricing-table.js';
		  script.async = true;
		  document.head.appendChild(script);
		}
	}, []);

	return (
		<ModalOverlay
			headerChildren={'Change Plan'}
			isOpen={isOpen}
			modalSize="modal-dialog-centered"
			onRequestClose={onRequestClose}
			isLoading={loadingCustomer}
			hideCloseButton={true}
			className="change-subscription-modal"
		>
			<stripe-pricing-table
				pricing-table-id="prctbl_1QNNhN2QR7fIwy8FNh1GXMYU"
				publishable-key="pk_test_51QMxJW2QR7fIwy8FPygP415qXk4zJADXNlZFl4OoPGRYNS3MGWJDstc6y2efMHw4XvDyKbUxW3SZValgCsAQMSjd00eMadF3oI"
				client-reference-id={customer?.customerId}
				customer-session-client-secret={customer?.clientSecret}
			>
			</stripe-pricing-table>
		</ModalOverlay>
	);
}

export default ChangeSubscriptionModal;
