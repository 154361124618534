import React from 'react';
import { FormGroup, Input } from 'components/core';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Text } from 'screens/ProductCatalog/components/columns';
import classNames from 'classnames';

interface TableColumnNotesProps {
	vendorFormMethods: UseFormReturn<any, object>;
	row: any;
	editTable: VendorTable | ProductTable | null
}

function TableColumnNotes({ vendorFormMethods, row, editTable}: TableColumnNotesProps) {
	if (!!editTable) {
		if (editTable?.rowId === row.index) {
			return (
				<Controller
					key={'notes'}
					control={vendorFormMethods.control}
					defaultValue={row.value || ''}
					name="specs.notes"
					render={({ field, fieldState }) => (
						<FormGroup
							field={field}
							fieldState={fieldState}
							className="mt-1 mb-0 pb-0"
						>
							<Input
								id="notes"
								maxLength={350}
								name={field.name}
								value={field.value}
								onChange={field.onChange}
								placeholder="Write..."
								error={!!fieldState.error?.message}
								className={classNames('fs-14', !!fieldState.error?.message ? 'custom-input-error' : 'custom-input')}
								style={{
									paddingBlock: 0,
									height: '38px',
								}}
							/>
						</FormGroup>
					)}
				/>
			);
		}
	}

	return <Text tooltip={row.value}>{row.value}</Text>;
}

export default TableColumnNotes;
