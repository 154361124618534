import PTDAPIs from 'api/PTDAPI';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { LoadingPage } from 'components/core';
import AuthContext from 'context/Auth/AuthContext';
import { PTDContext } from 'context/PTD/PTDContext';
import React, { ReactNode, useCallback } from 'react';

interface IProps {
	children: ReactNode;
}

function PTDStore(props: IProps) {
	const isInitialRender = React.useRef(true);

	// Loaders
	const [isInitializingPTD, setIsInitializingPTD] = React.useState(true);
	const [isInitializingPTDProducts, setIsInitializingPTDProducts] =
		React.useState(true);
	const [isInitializingPTDAccess, setIsInitializingPTDAccess] =
		React.useState(true);

	// Base states
	const { currentUser, companyProfile } = React.useContext(AuthContext);
	const [organizationList, setOrganizationList] = React.useState<
		OrganizationDTO[]
	>([]);
	const [defaultVendorType, setDefaultVendorType] = React.useState<VendorType>('Manufacturer');

	// States
	const [PTDMyLists, setPTDMyLists] = React.useState<PremiumTradeDirectory[]>(
		[]
	);
	const [PTDComanagedLists, setPTDComanagedLists] = React.useState<
		PremiumTradeDirectory[]
	>([]);
	const [PTDProducts, setPTDProducts] = React.useState<PTDProduct[]>([]);
	const [PTDAccess, setPTDAccess] = React.useState<PTDAccess[]>([]);
	const [PTDs, setPTDs] = React.useState<PTD[]>([]);

	const fetchPTDs = useCallback(async (orgDetailsResults: OrganizationDTO, signal?: AbortSignal) => {
		const results: PremiumTradeDirectory[] | null =
			await PTDAPIs.premiumTradeDirectories.GetPremiumTradeDirectories(
				signal
			);
		setPTDMyLists(
			results?.filter(
				(list) => list.ownedByCompanyId === orgDetailsResults?.id
			) || []
		);
		setPTDComanagedLists(
			results?.filter(
				(list) => list.ownedByCompanyId !== orgDetailsResults?.id
			) || []
		);

		results?.map((p) => {
			setPTDAccess((accesses) =>
				!accesses.some(
					(existingAccess) =>
						existingAccess.email === currentUser.email &&
						existingAccess.premiumTradeDirectoryId === p.id
				)
					? [
						...accesses,
						{
							name: currentUser.fullName || '',
							email: currentUser.email || '',
							company: currentUser.organizationId || 0,
							role: currentUser.roles?.toString() || '',
							permission: p?.currentUserAccessType || 'Edit',
							premiumTradeDirectoryId: p.id,
						},
					  ]
					: [...accesses]
			);
		});

		setIsInitializingPTD(false);
	}, [currentUser.email, currentUser.fullName, currentUser.organizationId, currentUser.roles]);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		async function fetchOrganizationList() {
			const results = await scopeMatchAPI.organization.GetOrganizationList(
				signal
			);
			setOrganizationList(results || []);
		}

		// Render only once
		if (currentUser && companyProfile && isInitialRender.current) {
			fetchPTDs(companyProfile, signal);
			fetchOrganizationList();
			isInitialRender.current = false;
		}

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [companyProfile, currentUser, fetchPTDs]);

	React.useEffect(() => {
		setDefaultVendorType(
			companyProfile?.organizationType === 'owner' ||
				companyProfile?.organizationType === 'distributor'
				? 'Manufacturer'
				: 'MaterialSupplier'
		);
		companyProfile?.organizationType === 'owner' ||
		companyProfile?.organizationType === 'distributor'
			? setPTDs([
				{ id: '0', name: 'Manufacturers', vendorType: 'Manufacturer' },
				{ id: '1', name: 'Distributors', vendorType: 'Distributor' },
			  ])
			: setPTDs([
				{
					id: '0',
					name: 'Material Suppliers',
					vendorType: 'MaterialSupplier',
				},
				{
					id: '1',
					name: 'Equipment Suppliers',
					vendorType: 'EquipmentSupplier',
				},
			  ]);

		return () => {
			// clear the data before component unmounts
			setPTDs([]);
		};
	}, [companyProfile?.organizationType]);

	const fetchProductsForPTDList = useCallback(
		async (idPTDList, signal) => {
			setPTDProducts([]);
			setIsInitializingPTDProducts(true);
			try {
				const result: PTDProductResponse | null =
					await PTDAPIs.premiumTradeDirectories.GetProductsAndSpecifications(
						{ premiumTradeDirectoryId: idPTDList },
						signal
					);
				if (result) {
					setPTDProducts(result.productAndSpecs);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsInitializingPTDProducts(false);
				return true;
			}
		},
		[setPTDProducts, setIsInitializingPTDProducts]
	);

	const fetchUserAccessForPTDList = useCallback(async (idPTDList, signal?) => {
		await getUserAccessForPTDList(idPTDList, signal);
	}, []);

	const initialState = {
		isInitializingPTD,
		isInitializingPTDProducts,
		isInitializingPTDAccess,
		// Profile
		getCompanyProfile,
		// Organization List
		getOrganizationList,
		addOrganization,
		// PTD Vendor Type
		getDefaultVendorType,
		// PTD
		getAllPTDs,
		getSinglePTD,
		// PTD List
		fetchPTDs,
		getPTDMyLists,
		getPTDComanagedLists,
		getSinglePTDList,
		addPTDList,
		updatePTDList,
		deletePTDList,
		// Product
		getPTDProducts,
		fetchProductsForPTDList,
		addProduct,
		updateProduct,
		deleteProduct,
		// Access
		getPTDAccess,
		fetchUserAccessForPTDList,
		addAccessToListForUser,
		updateAccessToListForUser,
		deleteAccessUser,
	};

	function getCompanyProfile(): OrganizationDTO | null {
		return Object.assign({}, companyProfile);
	}

	function getOrganizationList(): OrganizationDTO[] {
		return organizationList.slice();
	}

	function addOrganization(organization: OrganizationDTO) {
		setOrganizationList((organizationList) => [
			...organizationList,
			organization,
		]);
	}

	function getDefaultVendorType(): VendorType {
		return Object.assign({}, defaultVendorType);
	}

	function getAllPTDs(): PTD[] {
		return PTDs.slice();
	}

	function getSinglePTD(id: string | undefined): PTD | undefined {
		if (id !== undefined) {
			return Object.assign(
				{},
				PTDs.find((ptd) => ptd.id === id)
			);
		} else {
			return undefined;
		}
	}

	function getPTDMyLists(): PremiumTradeDirectory[] {
		return PTDMyLists?.slice();
	}

	function getPTDComanagedLists(): PremiumTradeDirectory[] {
		return PTDComanagedLists?.slice();
	}

	function getSinglePTDList(
		idPTDList: number | undefined,
		listKey: string | undefined
	): PremiumTradeDirectory | undefined {
		if (idPTDList === null) {
			return undefined;
		}

		let ptdList: PremiumTradeDirectory | undefined;
		switch (listKey) {
		case 'myList':
			ptdList = PTDMyLists.find((list) => list.id === idPTDList);
			break;
		case 'comanagedList':
			ptdList = PTDComanagedLists.find((list) => list.id === idPTDList);
			break;
		default:
			return undefined;
		}

		return ptdList ? { ...ptdList } : undefined;
	}

	function addPTDList(newList: PremiumTradeDirectory): void {
		setPTDMyLists((lists) => [...lists, newList]);
	}

	function updatePTDList(
		newList: PremiumTradeDirectory,
		idPTDList: number
	): void {
		setPTDMyLists((prevLists) => {
			return prevLists.map((list) =>
				list.id === idPTDList ? { ...newList } : list
			);
		});
	}

	function deletePTDList(idPTDList: number | null) {
		setPTDMyLists((prevLists) =>
			prevLists.filter((list) => list.id !== idPTDList)
		);
	}

	function getPTDProducts(): PTDProduct[] {
		return PTDProducts.slice();
	}

	function addProduct(newProduct: PTDProduct) {
		setPTDProducts((products) => [...products, newProduct]);
	}

	function updateProduct(editProduct: PTDProduct): void {
		setPTDProducts((products) => {
			const temp =
				products.map((product) =>
					product.id === editProduct.id ? { ...editProduct } : product
				) || [];
			return temp;
		});
	}

	function deleteProduct(idPTDProduct: number) {
		setPTDProducts((prevProducts) =>
			prevProducts.filter((product) => product.id !== idPTDProduct)
		);
	}

	async function getUserAccessForPTDList(
		idPTDList: number,
		signal?: AbortSignal
	) {
		setIsInitializingPTDAccess(true);
		try {
			const result: PTDAccess[] =
				await PTDAPIs.premiumTradeDirectories.GetUserAccessForList(
					{ premiumTradeDirectoryId: idPTDList },
					signal
				);
			setPTDAccess((accesses) => [
				...accesses,
				...result.filter((newAccess) => {
					const exists = accesses.some(
						(existingAccess) =>
							existingAccess.email === newAccess.email &&
							existingAccess.premiumTradeDirectoryId ===
								newAccess.premiumTradeDirectoryId
					);
					return !exists;
				}),
			]);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsInitializingPTDAccess(false);
		}
	}

	function getPTDAccess(): PTDAccess[] {
		return PTDAccess?.slice();
	}

	function addAccessToListForUser(newAccess: PTDAccess) {
		setPTDAccess((access) => [...access, newAccess]);
	}

	function updateAccessToListForUser(newAccess: PTDAccess) {
		setPTDAccess((prevAccess) => {
			return prevAccess.map((access) =>
				access.id === newAccess.id ? { ...newAccess } : access
			);
		});
	}

	function deleteAccessUser(idAccessUser: number) {
		setPTDAccess((prevAccess) =>
			prevAccess.filter((access) => access.id !== idAccessUser)
		);
	}

	return (
		<PTDContext.Provider value={initialState}>
			{initialState.isInitializingPTD ? <LoadingPage /> : props.children}
		</PTDContext.Provider>
	);
}

export default PTDStore;
