import axios, { AxiosError } from 'axios';

const feedback = {
	postFeedback: async (dataToSend: FeedbackDTO) => {
		const response = await axios.post(`${apiUrl}/feedback`, dataToSend)
			.then((data: { data: string; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	getFeedbackList: async () => {
		const response = await axios.get(`${apiUrl}/feedback`)
			.then((data: { data: FeedbackDTO[] }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	getFeedback: async (id: number) => {
		const response = await axios.get(`${apiUrl}/feedback/${id}`)
			.then((data: { data: FeedbackDTO }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	dismissFeedback: async (id: number) => {
		const response = await axios.post(`${apiUrl}/feedback/${id}/dismiss`, { id })
			.then((data: { data: string; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	completeFeedback: async (id: number) => {
		const response = await axios.post(`${apiUrl}/feedback/${id}/complete`, { id })
			.then((data: { data: string; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	elevateFeedback: async (id: number) => {
		const response = await axios.post(`${apiUrl}/feedback/${id}/elevate`, { id })
			.then((data: { data: string; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	deleteFeedback: async (id: number) => {
		const response = await axios.delete(`${apiUrl}/feedback/${id}`)
			.then((data: { data: string; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};

const apiUrl = process.env.REACT_APP_API_URL;
const coreAPI = {
	feedback,
	getCurrentUser: async () => {
		try {
			const response = await axios.get(`${apiUrl}/current-user`);
			return response.data as CurrentUserDTO;
		} catch (error) {
			if (error.response.status === 401) {
				window.location.reload();
				return error;
			}
			else {
				console.warn('Error getting the current user, probably an authentication issue');
				console.warn(error);
				return error;
			}
		}
	},
	getSettings: async () => {
		const response = await axios.get(`${apiUrl}/anon/settings`);
		return response.data as boolean;
	},
	updateUserInfo: async (request: UpdateTenantUserInfoRequest) => {
		const response = await axios.post(`${apiUrl}/tenant/user-update-info`, request);
		return response.data as UserDetailsDTO;
	},
	updateUserPassword: async (request: UpdateTenantUserPasswordRequest) => {
		const response = await axios.post(`${apiUrl}/tenant/user-update-password`, request);
		return response.data as UserDetailsDTO;
	},
	// new methods
	getTenantUsers: async (request: GetAllTenantUsersRequest) => {
		const response = await axios.get(`${apiUrl}/tenant/${request.tenantId}/all-users`);
		return response.data as UserDetailsDTO[];
	},
	getTenantUsersCSV: () => {
		return axios.get(`${apiUrl}/tenant/1/all-users?format=csv`, {
			responseType: 'blob',
		}).then((response) => {
			// Create a temporary URL for the blob
			const url = window.URL.createObjectURL(new Blob([response.data]));

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'scopematch-users.csv'; // Specify the file name

			// Programmatically click the link to trigger the download
			link.click();

			// Cleanup by revoking the temporary URL
			window.URL.revokeObjectURL(url);
		})
			.catch((error) => {
				console.error('Error downloading CSV:', error);
			});
	},
	getTenantUser: async (request: GetTenantUserRequest) => {
		const response = await axios.get(`${apiUrl}/tenant/${request.tenantId}/users/${request.userId}`);
		return response.data as UserDetailsDTO;
	},
	postTenantUser: async (request: UpsertTenantUserRequest) => {
		const response = await axios.post(`${apiUrl}/tenant/${request.tenantId}/users`, request);
		return response.data as UserDetailsDTO;
	},
	deleteTenantUser: (request: DeleteTenantUserRequest) => {
		return axios.delete(`${apiUrl}/tenant/${request.tenantId}/users`, { data: request });
	},
	getTenantUserEmails: async (request: GetTenantUserEmailsRequest) => {
		const response = await axios.get(`${apiUrl}/tenant/${request.tenantId}/user-emails`);
		return response.data as string[];
	},
	getRoles: async () => {
		const response = await axios.get(`${apiUrl}/roles`);
		return response.data as RoleDTO[];
	},
	getRole: async (request: GetRoleRequest) => {
		const response = await axios.get(`${apiUrl}/roles/${request.id}`);
		return response.data as RoleDTO;
	},
	postRole: async (request: PostRoleRequest) => {
		const response = await axios.post(`${apiUrl}/roles`, request);
		return response.data as RoleDTO;
	},
	getPermissions: async () => {
		const response = await axios.get(`${apiUrl}/tenant/permissions`);
		return response.data as PermissionDTO[];
	},
};

export default coreAPI;