import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Text } from 'screens/ProductCatalog/components/columns';
import { Tooltip } from '@mui/material';
import ValidationCircle from 'components/core/ValidationCircle';
import { IconBoxSeam, IconCircleTick, IconCircleTickOutlined, IconInfoRed, IconInfoYellow, IconLocationWhite } from 'assets/icons';


const PercentageIcon: React.FC<{ value: number }> = ({ value }) => {
	if (value < 60) {
	  return <IconInfoRed className="ps-1" />;
	} else if (value > 60 && value < 80) {
	  return <IconInfoYellow className="ps-1" />;
	} else if (value > 80 && value < 95) {
	  return <IconCircleTickOutlined className="ps-1" />;
	} else if (value > 95) {
	  return <IconCircleTick className="ps-1" />;
	}

	return null; // Fallback if no conditions match
};


interface TableColumnValidRowStatusProps {
	vendorFormMethods: UseFormReturn<any, object>,
	row: any,
	editTable: VendorTable | ProductTable | null
}

function TableColumnValidRowStatus({row, editTable}: TableColumnValidRowStatusProps) {
	if (!!editTable) {
		if (editTable?.rowId === row.index) {
			return <Text></Text>;
		}
	}

	if (row.value === undefined || row.value.length === 0) {
		return <Text></Text>;
	}

	const tooltipText = (
		<ul className="p-2 ps-3 m-0">
		  <li>Matching Score: {row.value}%</li>
		  <li>
			Breakdown:
				<ul className="ps-0">
			  		<li className="list-style-none">
						<IconLocationWhite className="my-1 me-2" />
						Location: {row.original.validatedLocation}%
						<PercentageIcon value={row.original.validatedLocation} />
					</li>
			  		<li className="list-style-none">
						<IconBoxSeam className="my-1 me-2" />
						Product: {row.original.validatedProduct}%
						<PercentageIcon value={row.original.validatedProduct} />
					</li>
				</ul>
		  </li>
		</ul>
	);

	return (
		<Tooltip title={tooltipText} placement="left">
			<div className="validation-container">
				<span className="me-2">
					{row.value}%
				</span>
				<ValidationCircle value={row.value} />
			</div>
		</Tooltip>
	);
}

export default TableColumnValidRowStatus;