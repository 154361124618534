import React from 'react';
import { Button, LoadingPage, Page } from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';

import ContactCard from 'screens/Requests/components/ContactCard';
import OrgLocationCard from 'screens/Requests/components/OrgLocationCard';
import OrgCertificationsCard from 'screens/Requests/components/OrgCertificationsCard';
import OrgCompanyValuesCard from 'screens/Requests/components/OrgCompanyValuesCard';
import OrgInsuranceRange from 'screens/Requests/components/OrgInsuranceRange';
import OrgProductCatalogCard from 'screens/Requests/components/OrgProductCatalogCard';
import LinkedOrganizationCard from 'screens/Requests/components/LinkedOrganizationCard';
import { oppositeOrganizationName } from 'components/helperfunctions';
import OrgFacilitiesCard from 'screens/Requests/components/OrgFacilitiesCard';
import OrgInfoCard from 'components/profile/OrgInfoCard';
import OrgSubsidiaryCompany from 'components/profile/OrgSubsidiaryCompany';
import OrgParentCompany from 'components/profile/OrgParentCompany';
import { usePTD } from 'context/PTD/PTDContext';
import AuthContext from 'context/Auth/AuthContext';

const CompanyProfile = () => {
	const pageTitle = 'Company Profile';
	const { companyProfile, isInitializing } = React.useContext(AuthContext);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [orgHeadOffice, setOrgHeadOffice] = React.useState<string>('');
	const [filteredParentCompanies, setFilteredParentCompanies] = React.useState<
		CorporateStructure[]
	>([]);
	const [filteredSubsidiaryCompanies, setFilteredSubsidiaryCompanies] =
		React.useState<CorporateStructure[]>([]);

	const { getOrganizationList } = usePTD();
	const organizationList = getOrganizationList();

	React.useEffect(() => {
		setIsLoading(isInitializing);

		setOrgHeadOffice(
			[
				companyProfile?.headOfficeLocation,
				companyProfile?.headOfficeLocationCountry,
			]
				.filter((part) => part !== undefined && part !== null && part !== '')
				.join(', ')
		);

		// Filter and ensure unique parent corporate structures, excluding the current organization.
		setFilteredParentCompanies(
			Array.from(
				new Map(
					companyProfile?.corporateStructures
						?.filter(
							(c) =>
								c.parentCompanyId !== companyProfile?.id &&
								c.subsidiaryCompanyId === companyProfile?.id
						)
						.map((c) => [c.parentCompanyId, c]) // Map each parentCompanyId to its corresponding record
				).values() // Take only unique records based on parentCompanyId
			)
		);

		// Filter and ensure unique corporate structures excluding the current organization.
		setFilteredSubsidiaryCompanies(
			Array.from(
				new Map(
					companyProfile?.corporateStructures
						?.filter(
							(c) =>
								c.subsidiaryCompanyId !== companyProfile?.id &&
								c.parentCompanyId === companyProfile?.id
						)
						.map((c) => [c.subsidiaryCompanyId, c]) // Map each subsidiaryCompanyId to its corresponding record
				).values() // Take only unique records based on subsidiaryCompanyId
			)
		);
	}, [companyProfile, isInitializing]);

	if (isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar pageTitle={pageTitle} />
				</div>
			</div>
			<div className="container-fluid mb-5 px-5 py-3">
				<div className="d-flex">
					{companyProfile && (
						<OrgInfoCard
							orgDetails={companyProfile}
							orgHeadOffice={orgHeadOffice}
						/>
					)}
					<div className="ms-auto">
						<Button
							to="/company-profile/update-profile"
							className="btn-primary"
						>
							Update Profile <IconEdit className="ms-2" />
						</Button>
					</div>
				</div>

				<div className="d-flex align-items-stretch gap-3">
					{filteredParentCompanies.length > 0 && (
						<OrgParentCompany
							filteredParentCompanies={filteredParentCompanies}
							organizationList={organizationList}
						/>
					)}
					{filteredSubsidiaryCompanies.length > 0 && (
						<OrgSubsidiaryCompany
							filteredSubsidiaryCompanies={filteredSubsidiaryCompanies}
							organizationList={organizationList}
						/>
					)}
				</div>

				<OrgFacilitiesCard
					orgFacilities={companyProfile?.organizationFacilities}
				/>
				<OrgLocationCard
					orgHeadOffice={orgHeadOffice}
					orgShippingLocation={companyProfile?.shippingLocation}
					orgManufacturingLocation={companyProfile?.fullManufacturingLocations}
					orgType={companyProfile?.organizationType}
					companyProfile={true}
				/>
				<ContactCard orgContact={companyProfile?.organizationContacts} />
				{companyProfile?.organizationType !== 'owner' && (
					<OrgProductCatalogCard
						orgProducts={companyProfile?.productSummaryList}
						showSeeDetails={true}
					/>
				)}
				<OrgCertificationsCard
					orgCertifications={companyProfile?.standardsAndCertification}
				/>
				<OrgCompanyValuesCard
					orgCompanyValues={companyProfile?.companyValues}
				/>
				<OrgInsuranceRange orgInsuranceRange={companyProfile?.insuranceRange} />
				<LinkedOrganizationCard
					organizationList={companyProfile?.manufacturersWorkWith}
					orgType={companyProfile?.organizationType}
					title={
						oppositeOrganizationName(companyProfile?.organizationType) +
						's Currently Working With'
					}
					orgName={companyProfile?.organizationName}
				/>

				{companyProfile?.organizationType !== 'manufacturer' && (
					<LinkedOrganizationCard
						organizationList={companyProfile?.ownersWorkWith}
						orgType={companyProfile?.organizationType}
						title={
							companyProfile?.organizationType === 'owner'
								? 'Distributors Currently Working With'
								: 'Owners Currently Working With'
						}
						orgName={companyProfile?.organizationName}
					/>
				)}
				<LinkedOrganizationCard
					organizationList={companyProfile?.approvedManufacturerList}
					orgType={companyProfile?.organizationType}
					title={`Approved Manufacturer${
						companyProfile?.organizationType === 'owner'
							? 's'
							: ' Listings (AML)'
					}`}
					showExplanation={false}
					isAML={true}
					orgName={companyProfile?.organizationName}
				/>
			</div>
		</Page>
	);
};

export default CompanyProfile;
