import React, { useState, useEffect, useCallback } from 'react';
import { Button, DataTable, LoadingPage, Page } from 'components/core';
import { filterByStatus, StatusFilterMethod, setStatusCellValue, ClearFilterButton, ActionIcon } from 'components/core';
import coreAPI from './coreAPI';
import { useNavigate } from 'react-router-dom';
import TitleBar from 'screens/Components/TitleBar';

const TenantUserListPage = () => {
	const tenantId = 1;
	const navigate = useNavigate();
	const [tenantUsers, setTenantUsers] = useState<ManageUserDTO[]>([]);
	const [roles, setRoles] = useState<RoleDTO[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [filtered, setFiltered] = useState<string[]>([]);
	const title = 'Manage Users';
	const loadData = useCallback(async () => {
		setLoading(true);
		coreAPI.getTenantUsers({ tenantId: 1 })
			.then((tenantUsers: React.SetStateAction<ManageUserDTO[]>) => {
				setTenantUsers(tenantUsers);
			})
			.then(() => coreAPI.getRoles())
			.then((roles: React.SetStateAction<RoleDTO[]>) => {
				setRoles(roles);
				setLoading(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantId]);

	useEffect(() => {
		loadData();
	}, [
		loadData
	]);

	const columns = [
		{
			Header: 'Name',
			accessor: (row: any) => row.fullName,
			id: 'Name',
			filterable: true,
		},
		{
			Header: 'Status',
			accessor: 'isActive',
			id: 'isActive',
			filterMethod: filterByStatus('isActive'),

			Filter: StatusFilterMethod,
			Cell: setStatusCellValue,
			defaultCanFilter: true
		},
		{
			Header: 'Email',
			accessor: 'email',
			filterable: true,
		},
		{
			Header: 'Organization',
			accessor: (row: any) => row.organizationName,
			id: 'Organization',
			filterable: true,
		},
		{
			Header: 'Role',
			accessor: 'rolesList',
			id: 'Role',
			filterable: true,
			Cell: (row: any) => {
				if (row.value === undefined || row.value.length === 0) {
					return 'None';
				}
				return readRolesById(row.value);
			}
		},
		{
			accessor: 'Actions',
			width: 90,
			Cell: <ActionIcon title="Details" type="open" className="float-right" />,
			Filter: <ClearFilterButton onClick={() => setFiltered([])} />,
			Header: 'Actions',
			id: 'Actions',
		}
	];

	function getActionLink(tenantId: any, rowId: any) {
		return `/admin/manage-users/${tenantId}/user-details/${rowId}`;
	}

	function onRowClick({ original }: { original?: any }) {
		navigate(getActionLink(tenantId, original.id));
	}

	function readRolesById(roleIds: string) {
		// turn the comma separated string roleIds into an array
		const role = roleIds.split(',');
		let roleText = '';
		role.forEach(id => {
			const role = roles.find(r => r.id === Number(id));
			if (role) {
				roleText += role.name + ',';
			}
		});
		if (roleText[roleText.length - 1] === ',') {
			roleText = roleText.slice(0, roleText.length - 1);
		}
		return roleText;
	}
	function downloadUsers() {
		coreAPI.getTenantUsersCSV();
	}

	if (loading === true) {
		return <LoadingPage />;
	}

	return (
		<Page title={title}>
			<TitleBar
				pageTitle={title}
			/>
			<div className="container-fluid px-5 py-3">
				<div className="bg-light border-bottom border-primary-200 px-3 py-1 d-flex align-items-center rounded-top custom-shadow">
					<h4 className="text-dark mb-0 py-3">ScopeMatch Users</h4>
					<Button
						className="ms-auto btn-primary"
						to={getActionLink(tenantId, -1)}>
						New user
					</Button>
					<Button
						className="ms-3 btn-outline-primary"
						onClick={downloadUsers}
					>
						Export CSV
					</Button>
				</div>
				<DataTable
					data={tenantUsers}
					columns={columns}
					showPagination={true}
					onFilteredChange={(filtered: any) => setFiltered(filtered)}
					filterable={true}
					filtered={filtered}
					onRowClick={onRowClick}
					loading={loading}
					sortable={true}
					resizable={true}
					defaultFiltered={[{ id: 'isActive', value: 'Active' }]}
				/>
			</div>
		</Page>
	);
};

export default TenantUserListPage;