import React, { HTMLAttributeAnchorTarget } from 'react';
import classNames from 'classnames';
import { Link, To } from 'react-router-dom';
import { Spinner } from 'components/core';
import { Tooltip } from '@mui/material';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: boolean;
	to?: To;
	target?: HTMLAttributeAnchorTarget;
	tooltip?: {
		title: string;
		placement: 'top' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | undefined;
	};
}

export const Button = (props: IProps) => {
	const { to, loading, disabled, className, children, target, ...otherProps } = props;

	if (to !== undefined && disabled !== true) {
		return (
			<Link to={to} className={classNames('btn', className)} target={target}>
				<span className="btn-content mb-0 pb-0">{children}</span>
			</Link>
		);
	}

	const buttonContent = (
		<button
			className={classNames('btn', className, loading === true && 'btn-loading')}
			disabled={disabled === true || loading === true}
			{...otherProps}
		>
			<span className="btn-content mb-0 pb-0 align-items-end">
				{children}
				{loading === true && (
					<Spinner className="ms-2" small={true} />
				)}
			</span>
		</button>
	);

	return props.tooltip ? (
		<Tooltip title={props.tooltip.title} placement={props.tooltip.placement}>
			{buttonContent}
		</Tooltip>
	) : (
		buttonContent
	);
};

