/* eslint-disable indent */
import PickListContext from 'context/PickList/PickListContext';
import React from 'react';
import {
	ActionMeta,
	default as ReactSelect,
	GroupBase,
	OptionsOrGroups,
	Props as ReactSelectProps,
	StylesConfig,
} from 'react-select';

interface IProps extends Omit<ReactSelectProps, 'options'> {
	pickListTypeName: string;
	error?: boolean;
	filter?: string[];
	additionalOptions?: { value: string; label: string }[];
	useValue1?: boolean;
	orderBy?: {
		order: 'description' | 'id';
		orderBy: 'asc' | 'desc';
	};
	hideSelectedOptions?: boolean;
	customSyle?: StylesConfig;
	editing?: boolean;
}

export const PickListSelect = (props: IProps) => {
	const elementRef = React.useRef<HTMLDivElement>(null);
	const isMounted = React.useRef(true);
	const {
		pickListTypeName,
		value,
		isMulti,
		error,
		onChange,
		filter,
		additionalOptions,
		useValue1,
		hideSelectedOptions,
		...otherProps
	} = props;
	const PickListCxt = React.useContext(PickListContext);
	const [options, setOptions] = React.useState<{
		value: string | undefined;
		label: string | undefined;
	}[]>([]);


	React.useEffect(() => {
		isMounted.current = true;

		if (additionalOptions) {
			setOptions((prevOptions) => {
				if (prevOptions.some((x) => additionalOptions.some((y) => x.value === y.value))) {
					return prevOptions;
				} else {
				return [...additionalOptions, ...prevOptions];
			}
		  });
		}

		return () => {
		  isMounted.current = false;
		};
	}, [additionalOptions]);


	const getPicklistOptions = React.useCallback((code: string) => {
		const pickListType = PickListCxt.pickListTypes.find((x) => x.code === code);
		const picklistOptions = PickListCxt.pickListItems.filter(
			(x) => x.pickListType?.code === pickListType?.code
		);

		const options = picklistOptions.map((item) => {
			if (useValue1 === true) {
				return { value: item.code, label: item.value1 };
			} else {
				return { value: item.code, label: item.description };
			}
		});

		// Filter out duplicates
		const uniqueOptions = Array.from(
			new Map(options.map((item) => [item.value, item])).values()
		);

		if (props.orderBy) {
			const { order, orderBy } = props.orderBy;
			uniqueOptions.sort((a, b) => {
				let valueA: string | number, valueB: string | number;
				switch (order) {
					case 'id':
						valueA =
							picklistOptions.find((item) => item.code === a.value)?.id || '';
						valueB =
							picklistOptions.find((item) => item.code === b.value)?.id || '';
						break;
					case 'description':
					default:
						valueA = a.label || '';
						valueB = b.label || '';
						break;
				}
				const comparison = valueA.toString().localeCompare(valueB.toString());
				return orderBy === 'asc' ? comparison : -comparison;
			});
		} else {
			uniqueOptions.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
		}

		if (filter !== undefined) {
			return uniqueOptions.filter((v) => v.value && !filter.includes(v.value));
		}
		return uniqueOptions;
	}, [PickListCxt.pickListItems, PickListCxt.pickListTypes, filter, props.orderBy, useValue1]);


	React.useEffect(() => {
		// Update options when pickListTypeName changes
		const newOptions = getPicklistOptions(pickListTypeName);
		setOptions(newOptions);
	}, [getPicklistOptions, pickListTypeName]);

	React.useEffect(() => {
		if (elementRef.current) {
			// Trigger a reflow
			elementRef.current.style.display = 'none';
			elementRef.current.offsetHeight; // Force a reflow, flushing the CSS changes
			elementRef.current.style.display = '';
		}
	}, []);


	const customStyles: StylesConfig = {
		...props.customSyle,
		control: (styles) => ({
			...styles,
			borderColor: error ? '#F15D1E' : '#BDB3AD',
			backgroundColor: error ? '#FFE6DC' : '#FFFFFF',
		}),
		option: (styles, { isSelected, isFocused }) => ({
			...styles,
			backgroundColor: isSelected
			  ? '#006361'
			  : isFocused
			  ? '#99c1c0'
			  : styles.backgroundColor,
			color: isSelected ? '#FFFFFF' : styles.color,
			':active': {
				...styles[':active'],
				backgroundColor: '#66a1a0', // Change this to your desired active color
			},
		}),
	};

	return (
		<div ref={elementRef} className="select-height-wrapper">
			<ReactSelect
				isMulti={isMulti || false}
				options={options}
				value={getValue(value, options)}
				onChange={onChangeVal}
				className="select-height"
				menuPosition="fixed"
				styles={customStyles}
				{...otherProps}
				closeMenuOnSelect={!isMulti}
				hideSelectedOptions={hideSelectedOptions ?? true}
			/>
		</div>
	);

	function getValue(
		value: unknown,
		options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
	) {
		if (Array.isArray(value)) {
			const val = options?.filter(
				(v: any) => value.findIndex((j: any) => j === v.value) !== -1
			);

			return val;
		}
		if (typeof value === 'string') {
			const val = options?.filter((v: any) => v.value === value);

			return val;
		}
		return props.editing ? [{ value: 'any', label: 'Any' }] : undefined;
	}

	function onChangeVal(newValue: unknown, actionMeta: ActionMeta<unknown>) {
		if (onChange === undefined) {
			return;
		}
		if (newValue && Array.isArray(newValue)) {
			const value = newValue.map((v) => v.value);

			if (isMounted.current) {
				onChange(value, actionMeta);
			}
			return;
		}
		if (newValue && Object.keys(newValue).includes('value')) {
			const thewNewValue = newValue as { value: any; label: any };
			const value = thewNewValue.value!;
			if (isMounted.current) {
				onChange(value, actionMeta);
			}
			return;
		}
		if (isMounted.current) {
			onChange(undefined, actionMeta);
		}
		return undefined;
	}
};