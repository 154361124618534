import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const tenantAPI = {

	getTenant: (itemId?: string) => {
		return axios.get(`${apiUrl}/tenant/${itemId}`)
			.then(response => response.data as Tenant);
	},
	getTenants: () => {
		return axios.get(`${apiUrl}/tenant`)
			.then(response => response.data as Tenant[]);
	},
	getTenantNames: () => {
		return axios.get(`${apiUrl}/tenant/names`).then(response => response.data as string[]);
	},
	saveTenant: (item: Tenant) => {
		return axios.post(`${apiUrl}/tenant`, item)
			.then(response => response.data as Tenant);
	},

};

export default tenantAPI;