import React from 'react';
import { ScatterChart } from '@mui/x-charts';
import { Container } from '@mui/material';


interface ScatterChartMUIProps {
	series: { id: string, z: string[], x: number, y: number }[],
	labelX?: string,
	labelY?: string,
	tooltipTitle?: string;
	colors?: [string, string];
}

// Custom Tooltip Component
const CustomTooltip = ({ itemData, series }: any, tooltipTitle?: string) => {
	if (itemData) {
		const zItems = series.valueFormatter().split(',');

		return (
			<div className="bg-black rounded-1 opacity-50 text-white p-x0 py-2" style={{width: '150px'}}>
				{tooltipTitle && <h6 className="text-center p-0 pb-1 m-0">{tooltipTitle}:</h6>}
				<ul className="fs-6 px-4 py-0 m-0">
					{zItems.map((value: string, index: number) => (
						<li key={`item-${index}`} className="text-break">{value}</li>
					))}
				</ul>
			</div>
		);
	}

	return null;
};


function ScatterChartMUI({ series, tooltipTitle, labelX, labelY, colors}: ScatterChartMUIProps) {

	return (
		<Container
			style={{
				margin: 'auto',
				marginTop: '4.5rem',
				width: '100%',
				height: '100%',
				padding: 0
			}}
		>

			<ScatterChart
				height={450}
				grid={{ horizontal: true, vertical: true }}
				series={series.map(data => ({
					data: [{ id: data.id, z: data.z.length, x: data.x, y: data.y }],
					valueFormatter: () => {
						const { z } = data;
						return `${z.map((value) => (value))}`;
					},
					markerSize: data.z.length * 3,
				}))}
				tooltip={{ trigger: 'item', itemContent: (itemData) => CustomTooltip(itemData, tooltipTitle) }}
				margin={{
					top: 10,
					bottom: 40,
				}}
				yAxis={[
					{
						label: labelY,
						min: 0,
						max: series.reduce((acc, curr) => Math.max(acc, curr.y), 0) + 1,
						tickInterval: Array.from({ length: series.reduce((acc, curr) => Math.max(acc, curr.y), 0) + 1 }, (_, i) => i),
					},
				]}
				xAxis={[
					{
						label: labelX,
						min: 0,
						max: series.reduce((acc, curr) => Math.max(acc, curr.x), 0) + 1,
						tickInterval: Array.from({ length: series.reduce((acc, curr) => Math.max(acc, curr.x), 0) + 1 }, (_, i) => i),
					},
				]}
				zAxis={[
					{
						colorMap:({
							type: 'continuous',
							min: 0,
							max: series.reduce((acc, curr) => Math.max(acc, curr.z.length), 0),
							color: colors || ['blue', 'red'],
						}),
					},
				]}
			/>
		</Container>
	);
}

export default ScatterChartMUI;
