import axios, { AxiosError } from 'axios';

const search = {
	GetSearchParameters: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/search-parameters/${dataToSend}`)
			.then((data: { data: MatchRequestDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},

	GetSearchParameterPanel: async (dataToSend: number, organizationId: number | undefined) => {
		if (organizationId === undefined) { organizationId = 0; }
		const response = await axios.get(`${apiUrl}/search-parameters/panel/${dataToSend}/${organizationId}`)
			.then((data: { data: MatchRequestDetailDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSearchResults: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/search-result/${dataToSend}`)
			.then((data: { data: MatchResultDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetUsersRequests: async () => {
		const response = await axios.get(`${apiUrl}/user-request`)
			.then((data: { data: SearchRequestSummaryDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetMatchResultStats: async (requestId: number, organizationId: number) => {
		const response = await axios.get(`${apiUrl}/matches/stat/${requestId}/${organizationId}`)
			.then((data: { data: MatchResultDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSavedMatches: async () => {
		const response = await axios.get(`${apiUrl}/matches`)
			.then((data: { data: MatchHistoryDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	PostRequest: async (dataToSend: MatchRequestDTO) => {
		const response = await axios.post(`${apiUrl}/search-request/`, dataToSend)
			.then((data: { data: MatchRequestDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	SaveMatch: async (requestId: number, organizationId: number) => {
		const response = await axios.post(`${apiUrl}/matches/${requestId}/${organizationId}`)
			.then((data: { data: string }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},

	DeleteSavedMatch: async (requestId: number, organizationId: number) => {
		const response = await axios.delete(`${apiUrl}/matches/${requestId}/${organizationId}`)
			.then((data: {}) => {
				return data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	SendMatchNotification: async (requestId: number | undefined, organizationId: number | undefined) => {
		const response = await axios.post(`${apiUrl}/matches/notification/${requestId}/${organizationId}`)
			.then((data: { data: string }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};
const organization = {
	GetOrganization: async () => {
		const response = await axios.get(`${apiUrl}/organization-onboarding`)
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetOrganizationList: async (signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/organizations`, {
			signal: signal
		})
			.then((data: { data: OrganizationDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSearchMatchOrganization: async (organizationId: number, requestId: number) => {
		const response = await axios.get(`${apiUrl}/organization/${organizationId}/matches/${requestId}`)
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	PostOrganization: async (dataToSend: OrganizationDTO) => {
		const response = await axios.put(`${apiUrl}/organization-onboarding`, dataToSend)
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	AddOrganization: async (dataToSend: OrganizationDTO) => {
		const response = await axios.post(`${apiUrl}/organization/`, dataToSend)
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetCompanyProfile: async (signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/user/organization`, {
			signal: signal
		})
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetProfileScrapper: async (url: string, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/profile-scrapper`, {
			signal: signal,
			params: {
				url
			}
		})
			.then((data: { data: ScrappedCompanyDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	DeleteOrganization: async (dataToSend: number) => {
		const response = await axios.delete(`${apiUrl}/organization/${dataToSend}`)
			.then((data: { data: boolean; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	ExportOrganization: async (dataToSend: number, organizationName: string) => {
		const response = await axios.get(`${apiUrl}/organization/${dataToSend}/export?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				if (organizationName.length < 3) {
					organizationName = 'Organization';
				}
				link.download = `${organizationName}.json`;
				link.click();
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};
const organizationProduct = {
	GetOrganizationProducts: async (OrganizationId: number) => {
		const response = await axios.get(`${apiUrl}/organization/${OrganizationId}/products`)
			.then((data: { data: OrganizationProductTypeDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	PostOrganizationProduct: async (dataToSend: POSTOrganizationProductDTO, organizationID: number) => {
		const response = await axios.post(`${apiUrl}/organization/${organizationID}/products/`, dataToSend)
			.then((data: { data: PostOrganizationProductRequest }) => {
				if (data.data.message === '') {
					return data.data;
				} else {
					const customMessage = `Products with these specs: ${data.data.message} already exist!`;
					console.error({ error: customMessage });
					return {
						data: data.data.data,
						message: customMessage,
						statusCode: 409
					} as PostOrganizationProductRequest;
				}
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return {
					message: err.message,
					statusCode: err.response?.status || 500,
				};
			});
		return response || null;
	},
	// UpdateOrganizationProduct: async (dataToSend: number) => {
	// 	const response = await axios.post(`${apiUrl}/updateproductfromorganization/${dataToSend}`)
	// 		.then((data: { data: OrganizationProductDTO; }) => {
	// 			return data.data;
	// 		})
	// 		.catch((err: AxiosError) => {
	// 			console.error({ error: err.message });
	// 			return null;
	// 		});
	// 	return response;
	// },
	DeleteOrganizationProduct: async (OrganizationId: number, ProductId: number) => {
		const response = await axios.delete(`${apiUrl}/organization/${OrganizationId}/products/${ProductId}/`)
			.then((data: { data: boolean; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	DeleteOrganizationProductType: async (OrganizationId: number, ProductTypeId: number) => {
		const response = await axios.delete(`${apiUrl}/organization/${OrganizationId}/product-category/${ProductTypeId}/`)
			.then((data: { data: boolean; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	}
};
const institution = {
	GetInstitutions: async (dataToSend: OrganizationTypeEnum | undefined) => {
		const response = await axios.get(`${apiUrl}/institution/${dataToSend}`)
			.then((data: { data: InstitutionDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	AddInstitution: async (dataToSend: InstitutionDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/institution`, dataToSend)
			.then((data: { data: InstitutionDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
};
const approvedManufacturers = {
	GetApprovedManufacturers: async () => {
		const response = await axios.get(`${apiUrl}/approved-manufacturers`)
			.then((data: { data: LinkedOrganizationDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	AddApprovedManufacturer: async (dataToSend: LinkedOrganizationDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/approved-manufacturers`, dataToSend)
			.then((data: { data: LinkedOrganizationDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
};
const product = {
	GetProductTypeList: async () => {
		const response = await axios.get(`${apiUrl}/product/spec-list/`)
			.then((data: { data: ProductTypeDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};
const pickListItems = {
	AddProductSpec: async (dataToSend: SpecificationUpsertDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/product/spec/`, dataToSend)
			.then((data: { data: SpecificationUpsertDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
	AddProductTable: async (dataToSend: SpecificationUpsertDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/product/type/`, dataToSend)
			.then((data: { data: SpecificationUpsertDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
	GetProductSpecificationList: async (dataToSend: number | undefined) => {
		const response = await axios.get(`${apiUrl}/product/spec-list/${dataToSend}`)
			.then((data: { data: SpecificationGroupDTO }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
	AddValueToSpecification: async (dataToSend: SpecificationUpsertDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/product/spec-value`, dataToSend)
			.then((data: { data: SpecificationUpsertDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
};
const dataVisualization = {
	GetNodeConnections: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/data-visualization/nodes/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.download = 'DataVisualizationNodeConnections.json'; // Specify the file name
				link.click();
				window.URL.revokeObjectURL(url); // Cleanup the temporary URL
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetNodeVisualization: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/data-visualization/chart/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.download = 'DataVisualizationDashboard.json';
				link.click();
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetNodeVisualizationForDashboard: async () => {
		const response = await axios.get(`${apiUrl}/data-visualization/chart`)
			.then((response) => {
				return response.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSearchResults: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/data-visualization/searches/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.download = 'DataVisualizationSearches.json';
				link.click();
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSavedSearchMatches: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/data-visualization/saved-matches/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.download = 'DataVisualizationSavedMatches.json';
				link.click();
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};

const locationSuggestions = {
	GetLocationSuggestions: async (dataToSend: string) => {
		try {
			const response = await axios.get(`${apiUrl}/location-suggestions/${dataToSend}`);
			return response.data;
		} catch (err) {
			console.error({ error: err.message });
			return null;
		}
	},
};
const apiUrl = process.env.REACT_APP_API_URL;
const scopeMatchAPI = {
	search,
	organization,
	institution,
	approvedManufacturers,
	organizationProduct,
	product,
	dataVisualization,
	locationSuggestions,
	pickListItems
};

export default scopeMatchAPI;