import SubscriptionAPIs from 'api/SubscriptionAPIs';
import { useReducer, useEffect } from 'react';


interface State {
	customer: StripeCustomer | null;
	loadingCustomer: boolean;
}

type Action =
	| { type: 'FETCH_START_CUSTOMER' }
	| { type: 'FETCH_CUSTOMER'; payload: StripeCustomer | null };


const initialState: State = {
	customer: null,
	loadingCustomer: false,
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
	case 'FETCH_START_CUSTOMER':
		return { ...state, loadingCustomer: true };
	case 'FETCH_CUSTOMER':
		return { ...state, loadingCustomer: false, customer: action.payload };
	default:
		return state;
	}
}

export const useSubscriptionStore = () => {
	const [state, dispatch] = useReducer(reducer, initialState);


	const fetchCustomer = async (signal?: AbortSignal) => {
		dispatch({ type: 'FETCH_START_CUSTOMER' });
		const data = await SubscriptionAPIs.GetSubscriptionCustomer(signal);
		dispatch({ type: 'FETCH_CUSTOMER', payload: data });
	};

	useEffect(() => {
		fetchCustomer();
	}, []);

	return { ...state };
};
