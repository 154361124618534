import React from 'react';
import { ActionIcon, Button } from 'components/core';
import { IconCheck, IconClose } from 'assets/icons';

interface IProps {
	isEditing: boolean;
	disabled?: boolean;
	onClickEdit: () => void;
	onClickDelete?: () => void;
	onClickSave: () => void;
	onClickCancel: () => void;
	onClickDuplicate?: () => void;
	onClickView?: () => void;
	isSaving?: boolean;
	canEdit?: boolean | null | undefined,
}
export const Buttons = (props: IProps) => {
	const {
		isEditing, disabled,
		onClickCancel, onClickDelete,
		onClickEdit, onClickSave,
		onClickDuplicate, onClickView,
		isSaving, canEdit = true
	} = props;

	if (isEditing === true) {
		return (
			<React.Fragment>
				<Button
					className="btn-primary me-2"
					style={{padding: '0.40rem'}}
					onClick={onClickSave}
					loading={isSaving}
				>
					{!isSaving && <IconCheck className="svg-18" />}
				</Button>
				<Button
					className="btn-outline-primary me-3"
					style={{padding: '0.40rem'}}
					onClick={onClickCancel}
					disabled={isSaving}
				>
					<IconClose className="svg-20" />
				</Button>
			</React.Fragment>

		);
	}
	return (
		<div className="d-flex justify-content-end">
			{canEdit &&
				onClickDuplicate &&
					<ActionIcon
						title="Duplicate"
						type="duplicate"
						disabled={disabled}
						onClick={onClickDuplicate}
						className="pe-1 ps-0"
					/>
			}

			{canEdit && <ActionIcon
				title="Edit"
				type="edit"
				disabled={disabled}
				onClick={onClickEdit}
				className="pe-1 ps-0"
			/>
			}

			{canEdit && onClickDelete && <ActionIcon
				title="Delete"
				type="delete"
				disabled={disabled}
				onClick={onClickDelete}
				className="pe-1 ps-0"
			/>}
			{onClickView && <ActionIcon
				title="View Details"
				type="open"
				disabled={disabled}
				onClick={onClickView}
				className="pe-0 ps-2"
			/>}
		</div>
	);
};