import { ModalOverlay } from 'components/core';
import React from 'react';


interface AutocompleteModalProps {
	isOpen: boolean;
	companyUrl: string | null;
	onRequestClose: () => void;
	onAutocompleteProfile: (companyUrl: string | null) => Promise<boolean>;
}


function AutocompleteModal({isOpen, companyUrl, onRequestClose, onAutocompleteProfile}: AutocompleteModalProps) {
	const isMounted = React.useRef(true);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false; // cleanup on unmount
		};
	}, []);

	async function onAutocompleteProfileConfirm() {
		if (companyUrl !== undefined) {
			setIsLoading(true);

			await onAutocompleteProfile(companyUrl);

			if (isMounted.current) {
				setIsLoading(false);
				onRequestClose();
			}
		}
	}

	return (
		<div>
			<ModalOverlay
				isOpen={isOpen}
				modalSize="modal-dialog-centered"
				onRequestClose={onRequestClose}
				confirmButtonChildren="Accept"
				cancelButtonChildren="Cancel"
				confirmButtonAction={onAutocompleteProfileConfirm}
				cancelButtonAction={onRequestClose}
				isLoading={isLoading}
				loaderMessage="Processing the URL… This may take up to three minutes. Thank you for your patience!"
				hideCloseButton={true}
			>
				<p className="text-justify">
					By clicking <strong>'Accept'</strong>, I confirm that I am the legal owner or authorized representative of the website <a href={companyUrl || undefined} className="text-primary">{companyUrl}</a> and grant ScopeMatch explicit permission to access and collect data from it to complete or update the platform profile information.
				</p>
			</ModalOverlay>
		</div>
	);
}

export default AutocompleteModal;
