import { DataTable } from 'components/core';
import { usePTDVendors } from 'context/PTDVendors/PTDVendorsContext';
import React, { useCallback } from 'react';
import { Column } from 'react-table';
import ViewVendorProfileModal from 'screens/PremiumTradeDirectory/components/modals/ViewVendorProfileModal';
import { usePTD } from 'context/PTD/PTDContext';
import { IconCircleTick } from 'assets/icons';

interface PremiumTradeDirectoryListsByVendorsProps {
	vendorType: number;
}

function PremiumTradeDirectoryListsByVendors({
	vendorType,
}: PremiumTradeDirectoryListsByVendorsProps) {
	// Contexts
	const { getPTDVendorTab, fetchDataForVendorTab } = usePTDVendors();
	const PTDVendorTab = getPTDVendorTab();
	const { getOrganizationList } = usePTD();
	const organizationList = getOrganizationList();

	const BASE_COLUMNS = React.useMemo(
		() => [
			{
				Header: 'Vendor Name',
				accessor: 'vendorName',
				filterable: true,
				sortable: true,
				width: 200,
				className: 'fixed-column fixed-first',
				headerClassName: 'fixed-column fixed-first',
				onCellClick: ({ row }: any) => {
					openViewVendorProfileModal(
						row.original.id || 0,
						row.original.vendorName
					);
				},
			},
			{
				Header: 'Total',
				accessor: 'count',
				filterable: false,
				sortable: true,
				width: 60,
				className: 'fixed-column fixed-last text-end pe-3',
				headerClassName: 'fixed-column fixed-last',
			},
		],
		[]
	);
	const [columns, setColumns] = React.useState<Column<any>[]>([
		...BASE_COLUMNS,
	]);

	// View Vendor Profile Modal
	const [showViewVendorProfileModal, setViewVendorProfileModal] =
		React.useState<{
			show: boolean;
			id: number | null;
			vendorName: string | null;
		}>({ show: false, id: null, vendorName: null });

	// Memoize fetchData using useCallback
	const fetchDataForVendorTabCallback = useCallback(
		(signal) => {
			fetchDataForVendorTab(vendorType, signal);
		},
		[fetchDataForVendorTab, vendorType]
	);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		fetchDataForVendorTabCallback(signal);

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [fetchDataForVendorTabCallback]);

	React.useEffect(() => {
		if (PTDVendorTab.premiumTradeDirectoryNames) {
			const dynamicColumns = PTDVendorTab.premiumTradeDirectoryNames.map(
				(name) => ({
					Header: name,
					filterable: false,
					sortable: false,
					className: 'text-center',
					Cell: (row: any) => {
						const ptdNames = row.original.ptdNames as string[];
						return ptdNames.includes(name) ? (
							<IconCircleTick className="mx-2 svg-16" />
						) : (
							''
						);
					},
				})
			);

			setColumns(() => {
				return [BASE_COLUMNS[0], ...dynamicColumns, BASE_COLUMNS[1]];
			});
		}
	}, [BASE_COLUMNS, PTDVendorTab.premiumTradeDirectoryNames]);

	// Open Vendor Profile Modal
	function openViewVendorProfileModal(id: number, vendorName: string) {
		setViewVendorProfileModal({ show: true, id, vendorName });
	}

	// Close Vendor Profile Modal
	function closeViewVendorProfileModal() {
		setViewVendorProfileModal({ show: false, id: null, vendorName: null });
	}

	return (
		<div>
			<DataTable
				data={PTDVendorTab.vendorData}
				columns={columns}
				filterable={true}
				sortable={true}
				resizable={true}
				noDataText="No data found."
				className="with-fixed-columns -striped"
			/>

			{showViewVendorProfileModal.show && (
				<ViewVendorProfileModal
					isOpen={showViewVendorProfileModal.show}
					id={showViewVendorProfileModal.id}
					vendorName={showViewVendorProfileModal.vendorName}
					organizationList={organizationList}
					onRequestClose={closeViewVendorProfileModal}
				/>
			)}
		</div>
	);
}

export default PremiumTradeDirectoryListsByVendors;
