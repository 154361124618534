import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

const premiumTradeDirectories = {
	GetPremiumTradeDirectories: async (signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/premium-trade-directory`, {
			signal: signal
		})
			.then((data: { data: PremiumTradeDirectory[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err?.response?.data?.responseStatus?.message });
				return null;
			});
		return response;
	},
	UpdatePremiumTradeDirectory: async (dataToSend: PremiumTradeDirectoryRequest) => {
		const response = await axios.put(`${apiUrl}/premium-trade-directory/`, dataToSend)
			.then((data: { data: any; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while updating the Vendor List');
				return null;
			});
		return response;
	},
	DeletePremiumTradeDirectory: async (premiumTradeDirectoryId: number | null) => {
		const response = await axios.delete(`${apiUrl}/premium-trade-directory/${premiumTradeDirectoryId}`)
			.then((data: AxiosResponse<{ message: string; }>) => {
				return data.data.message;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while deleting the Vendor List');
				return null;
			});
		return response;
	},
	GetProductsAndSpecifications: async (dataToSend: {premiumTradeDirectoryId: number}, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/premium-trade-directory/product/`, {
			signal: signal,
			params: dataToSend
		})
			.then((data: { data: PTDProductResponse }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err?.response?.data?.responseStatus?.message });
				return null;
			});
		return response;
	},
	UpdateProduct: async (dataToSend: PTDProductRequest) => {
		const response = await axios.put(`${apiUrl}/premium-trade-directory/product/`, dataToSend)
			.then((data: { data: PTDProduct }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while updating the product');
				return null;
			});
		return response;
	},
	DeleteProduct: async (dataToSend: PTDProductDeleteRequest) => {
		const response = await axios.delete(`${apiUrl}/premium-trade-directory/product/`, {
			params: dataToSend
		})
			.then((data: AxiosResponse<string>) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while deleting the product');
				return null;
			});
		return response;
	},
	GetVendorsPTD: async (dataToSend: {premiumTradeDirectoryId : number}, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/premium-trade-directory/vendors/`, {
			signal: signal,
			params: dataToSend
		})
			.then((data: { data: PTDVendor[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err?.response?.data?.responseStatus?.message });
				return [];
			});
		return response;
	},
	GetVendorsTab: async (dataToSend: {premiumTradeDirectoryId : number}, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/premium-trade-directory/vendors-tab/`, {
			signal: signal,
			params: dataToSend
		})
			.then((data: { data: PTDVendorTab[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err?.response?.data?.responseStatus?.message });
				return [];
			});
		return response;
	},
	GetVendorTab: async (vendorType: number, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/vendor-tab/`, {
			signal: signal,
			params: {vendorType}
		})
			.then((data: { data: PTDVendorTypeTab }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err?.response?.data?.responseStatus?.message });
				return null;
			});
		return response;
	},
	GetVendorsSpecs: async (dataToSend: {id : number}, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/premium-trade-directory/vendor-specs/`, {
			signal: signal,
			params: dataToSend
		})
			.then((data: { data: PTDVendorSpecs }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err?.response?.data?.responseStatus?.message });
				return null;
			});
		return response;
	},
	UpdateVendor: async (dataToSend: PTDVendorPutRequest) => {
		const response = await axios.put(`${apiUrl}/premium-trade-directory/vendors/`, dataToSend)
			.then((data: { data: PTDVendor; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while adding a vendor');
				return null;
			});
		return response;
	},
	DeleteVendor: async (vendorId: number) => {
		const response = await axios.delete(`${apiUrl}/premium-trade-directory/vendor/${vendorId}`)
			.then((data: AxiosResponse<{ message: string; }>) => {
				return data.data.message;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while deleting the product');
				return null;
			});
		return response;
	},
	UpdateVendorStatus: async (Id: number) => {
		const response = await axios.put(`${apiUrl}/premium-trade-directory/vendor/${Id}/status`)
			.then((data: { data: PTDVendor[]; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while adding a vendor');
				return null;
			});
		return response;
	},
	GetUserAccessForList: async (dataToSend: {premiumTradeDirectoryId: number}, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/premium-trade-directory/user-permissions`, {
			signal: signal,
			params: dataToSend
		})
			.then((data: { data: PTDAccess[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err?.response?.data?.responseStatus?.message });
				return [];
			});
		return response;
	},
	AddUserAccess: async (dataToSend: PTDAccessRequest) => {
		const response = await axios.post(`${apiUrl}/premium-trade-directory/user-permissions`, dataToSend)
			.then((data: { data: PTDAccess; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while adding a vendor');
				return null;
			});
		return response;
	},
	UpdateUserAccess: async (dataToSend: Partial<PTDAccessPutRequest>) => {
		const response = await axios.put(`${apiUrl}/premium-trade-directory/user-permissions`, dataToSend)
			.then((data: { data: PTDAccess; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while updating the product');
				return null;
			});
		return response;
	},
	DeleteUserAccess: async (accessId: number) => {
		const response = await axios.delete(`${apiUrl}/premium-trade-directory/user-permissions${accessId}`)
			.then((data: AxiosResponse<{ message: string; }>) => {
				return data.data.message;
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while deleting the access');
				return null;
			});
		return response;
	},
};

const apiUrl = process.env.REACT_APP_API_URL;
const PTDAPIs = {
	premiumTradeDirectories
};

export default PTDAPIs;