import React from 'react';
import EnhancedTable from 'vna/components/tables/TableMUI';
import { DynamicTableCell, DynamicTableData } from 'vna/types/interfaces';
import Accordion from 'vna/components/accordion/Accordion';
import ScatterChartMUI from 'vna/components/charts/ScatterChartMUI';
import lodash from 'lodash';
import { styled, Tooltip } from '@mui/material';
import { IconHelpCircle } from 'assets/icons';


interface AccordionProductsVendorsPerCountryProps {
    vendorsPerCountryArray: DynamicTableData[];
    productsPerCountryArray: DynamicTableData[];
}


function AccordionProductsVendorsPerCountry({
	vendorsPerCountryArray, productsPerCountryArray
}: AccordionProductsVendorsPerCountryProps) {

	// Merge the two arrays
	const itemsPerCountryArray = vendorsPerCountryArray.map(item1 => {
		const match = productsPerCountryArray.find(item2 => item2.country === item1.country);
		return {
			...item1,
			...match
		};
	});

	const groupedData = lodash.map(
		lodash.groupBy(itemsPerCountryArray, (item) => `${item.vendor}-${item.product}`),
		(vendorProductGroup, key) => {
		 	const countries = vendorProductGroup.map((item) => item.country) as string[];
			const products = vendorProductGroup.map((item) => item.product as number).at(0) || 0;
		 	const vendors = vendorProductGroup.map((item) => item.vendor as number).at(0) || 0;
			return { id: key, z: countries, x: products, y: vendors };
		}
	);

	const itemsPerCountryColumns: readonly DynamicTableCell[] = [
		{
			id: 'country',
			numeric: false,
			disablePadding: false,
			label: 'Country',
		},
		{
			id: 'vendor',
			numeric: false,
			disablePadding: true,
			label: 'Vendor',
		},
		{
			id: 'product',
			numeric: false,
			disablePadding: false,
			label: 'Product',
		},
	];

	const HelpIcon = styled(IconHelpCircle)(({ theme }) => ({
		width: 20,
		height: 20,
		marginLeft: theme.spacing(1), // Adds some space between text and icon
		color: '#666',
		cursor: 'pointer', // Makes it look clickable
		'&:hover': {
		  color: '#006361', // Change color on hover
		},
	}));

	return (
		<Accordion label="Countries - No. Vendors and No. Products">
			<div className="row gx-4 justify-content-center">
				<div className="col-lg-6 col-md-12">
					<ScatterChartMUI
						series={groupedData}
						tooltipTitle="Countries"
						labelX="No. Products"
						labelY="No. Vendors"
					/>
				</div>

				<div className="col-lg-6 col-md-12 mt-2">
					<EnhancedTable
						rows={itemsPerCountryArray}
						columns={itemsPerCountryColumns}
						chartColumns={{vendor: 'No. Vendor', product: 'No. Product'}}
						toolbarChildren={
							<Tooltip className="me-3" placement="top-end" title={
								<p  className="m-2">
									This chart and table show the number of vendors and products associated with each country.
									<ul>
										<li>
											Points in the top-right indicate countries with both a large number of vendors and products, suggesting high engagement or dependency.
										</li>
										<li>
											Points in the bottom-left represent countries with fewer vendors and products, potentially signaling lower importance or exposure.
										</li>
									</ul>
									Use this visualization to identify countries that are critical to your operations or pose risks due to concentrated dependency.
								</p>
							}>
								<HelpIcon />
							</Tooltip>
						}
					/>
				</div>
			</div>
		</Accordion>
	);
}

export default AccordionProductsVendorsPerCountry;
