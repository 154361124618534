import React from 'react';
import { Button } from 'components/core';
import Modal from 'react-modal';
import classNames from 'classnames';

interface IProps {
	className?: string;
	headerChildren?: React.ReactNode;
	footerChildren?: React.ReactNode;
	confirmButtonChildren?: React.ReactNode;
	cancelButtonChildren?: React.ReactNode;
	confirmButtonLink?: string;
	cancelButtonLink?: string;
	confirmButtonAction?: () => void;
	cancelButtonAction?: () => void;
	isOpen: boolean;
	onRequestClose?: () => void;
	hideCloseButton?: boolean;
	modalSize?: string;
	isLoading?: boolean;
	loaderMessage?: string;
	titleSize?: string;
	confirmButtonDisabled?: boolean;
	confirmButtonFirst?: boolean;
}

const ModalOverlay = (props: React.PropsWithChildren<IProps>) => {
	const ReactModal = Modal as unknown as React.FC<any>;

	const {
		className,
		isOpen,
		children,
		onRequestClose,
		hideCloseButton,
		headerChildren,
		footerChildren,
		cancelButtonChildren,
		cancelButtonAction,
		cancelButtonLink,
		confirmButtonChildren,
		confirmButtonAction,
		confirmButtonLink,
		modalSize,
		isLoading,
		confirmButtonDisabled,
		confirmButtonFirst = false,
	} = props;
	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			className={{
				base: `modal-dialog ${modalSize}`,
				afterOpen: '',
				beforeClose: ''
			}}
			overlayClassName={{
				base: classNames('modal', className),
				afterOpen: 'show',
				beforeClose: 'hide'
			}}
			closeTimeoutMS={200}
			shouldCloseOnOverlayClick={isLoading !== false || true}
			role="dialog"
			ariaHideApp={false}
		>
			<div className="modal-content pt-3 mt-2" role="document">
				{isLoading !== true && (
					<React.Fragment>
						{!hideCloseButton && (
							<button type="button" className="modal-close p-2" aria-label="Close" onClick={onRequestClose}>
								<span aria-hidden="true">&times;</span>
							</button>
						)}
						{headerChildren &&
							<div className={classNames('modal-header font-weight-bold mb-3 py-0  text-capitalize', props.titleSize ? props.titleSize : 'h4')}>

								{headerChildren}
							</div>}
					</React.Fragment>
				)}
				<div className="modal-body py-0">
					{isLoading !== true ? (
						children
					) : (
						<React.Fragment>
							{props.loaderMessage && <p className="text-muted fst-italic">
								{props.loaderMessage}
							</p>}
							<div className="loader-container ball-chasing">
								<div className="loader">
									<div className="ball-1"></div>
									<div className="ball-2"></div>
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
				{isLoading !== true && (
					<React.Fragment>
						{(footerChildren || cancelButtonChildren || confirmButtonChildren) && (
							<div className="modal-footer flex-nowrap justify-content-between">
								{cancelButtonChildren && (
									<Button
										to={cancelButtonLink}
										onClick={cancelButtonAction}
										className={`btn btn-outline-primary flex-fill ${confirmButtonFirst ? 'btn-cancel-last' : 'btn-cancel-first'}`}
									>
										{cancelButtonChildren}
									</Button>
								)}
								{(
									<Button
										to={confirmButtonLink}
										onClick={confirmButtonAction}
										className={`btn btn-primary ms-3 flex-fill ${confirmButtonFirst ? 'btn-confirm-first' : 'btn-confirm-last'}`}
										disabled={confirmButtonDisabled}
									>
										{confirmButtonChildren}
									</Button>
								)}
								{footerChildren}
							</div>
						)}
					</React.Fragment>
				)}
			</div>
		</ReactModal>
	);
};

export default ModalOverlay;
