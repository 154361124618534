import React, { useCallback } from 'react';
import { ActionIcon, DataTable, Page } from 'components/core';
import { usePTD } from 'context/PTD/PTDContext';
import { useParams } from 'react-router-dom';
import { Text } from 'screens/ProductCatalog/components/columns';
import TitleBar from 'screens/Components/TitleBar';
import ViewVendorProfileModal from 'screens/PremiumTradeDirectory/components/modals/ViewVendorProfileModal';
import { usePTDVendors } from 'context/PTDVendors/PTDVendorsContext';
import { groupBy } from 'lodash';

function PTDVendorsOverview() {
	const params = useParams();
	const idPTDList = Number(params.idPTDList);
	const listName = params.listName;

	// Get PTD List
	const { getOrganizationList, getSinglePTDList } = usePTD();
	const {
		getPTDVendorsTab,
		fetchVendorsForVendorsTab,
		isInitializingPTDVendorsTab,
	} = usePTDVendors();

	const PTDList = getSinglePTDList(idPTDList, listName);
	const vendors = Object.values(getPTDVendorsTab());
	const PTDVendors = groupBy(
		vendors,
		(vendor: PTDVendorTab) => vendor.vendorName
	);

	const data = Object.keys(PTDVendors).map((vendorName) => {
		const vendorGroup = PTDVendors[vendorName];
		const uniqueLocations = Array.from(
			new Set(vendorGroup.flatMap((vendor) => vendor.operatingLocations))
		);
		const { id, listBy, listBySector, listByIndustry } = vendorGroup[0]; // Assuming these properties are the same for all items in the group

		return {
			id,
			vendorName,
			vendors: vendorGroup,
			operatingLocations: uniqueLocations,
			listBy,
			listBySector,
			listByIndustry,
		};
	});

	// Get Organizations
	const organizationList = getOrganizationList();

	// General Page Info
	const pageTitle = PTDList
		? `Vendors Overview for ${PTDList.premiumTradeDirectoryName}`
		: 'Vendors Overview';

	// View Vendor Profile Modal
	const [showViewVendorProfileModal, setViewVendorProfileModal] =
		React.useState<{
			show: boolean;
			id: number | null;
			vendorName: string | null;
		}>({ show: false, id: null, vendorName: null });

	// Memoize fetchData using useCallback
	const fetchVendorsForVendorsTabCallback = useCallback(
		(signal) => {
			fetchVendorsForVendorsTab(idPTDList, signal);
		},
		[idPTDList, fetchVendorsForVendorsTab]
	);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		fetchVendorsForVendorsTabCallback(signal);

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [fetchVendorsForVendorsTabCallback]);

	const columns = [
		{
			Header: 'Vendor Name',
			accessor: 'vendorName',
			filterable: true,
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: 'Locations',
			accessor: 'operatingLocations',
			filterable: true,
			Cell: (row: any) => {
				return (
					<Text>
						{row.value &&
							row.value.length > 0 &&
							row.value.map((v: string, i: number) => <div key={i}>{v}</div>)}
					</Text>
				);
			},
		},
		{
			Header: 'Listed by',
			accessor: 'listBy',
			filterable: true,
			className: 'text-end',
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: 'Listed by within the same Sector(s)',
			accessor: 'listByIndustry',
			filterable: true,
			className: 'text-end',
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: 'Listed by within the same Industry(ies)',
			accessor: 'listBySector',
			filterable: true,
			className: 'text-end',
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			},
		},
		{
			accessor: 'View',
			Cell: (row: any) => (
				<ActionIcon
					title="Details"
					type="open"
					onClick={() =>
						openViewVendorProfileModal(row.original.id, row.original.vendorName)
					}
					className="float-end"
				/>
			),
			Header: '',
			filterable: false,
			id: 'View',
		},
	];

	// Open Vendor Profile Modal
	function openViewVendorProfileModal(id: number, vendorName: string) {
		setViewVendorProfileModal({ show: true, id, vendorName });
	}

	// Close Vendor Profile Modal
	function closeViewVendorProfileModal() {
		setViewVendorProfileModal({ show: false, id: null, vendorName: null });
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar pageTitle={pageTitle} />
				</div>
			</div>
			<div className="container-fluid mb-2 px-5 py-3">
				<div className="row">
					<div className="col-12">
						<DataTable
							data={data}
							columns={columns}
							filterable={true}
							resizable={true}
							loading={isInitializingPTDVendorsTab}
							noDataText="No vendors found"
						/>
					</div>
				</div>
			</div>

			{showViewVendorProfileModal.show && (
				<ViewVendorProfileModal
					isOpen={showViewVendorProfileModal.show}
					id={showViewVendorProfileModal.id}
					vendorName={showViewVendorProfileModal.vendorName}
					organizationList={organizationList}
					onRequestClose={closeViewVendorProfileModal}
				/>
			)}
		</Page>
	);
}

export default PTDVendorsOverview;
