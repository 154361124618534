import React from 'react';
import NetworkGraph from '../components/graphs/NetworkGraph/NetworkGraph';
import GeneralStats from '../components/stats/GeneralStats';
import GroupedByStats from '../components/stats/GroupedByStats/GroupedByStats';
import EnhancedTable from '../components/tables/TableMUI';
import Accordion from '../components/accordion/Accordion';
import {
	AmlExplorerRows,
	DynamicTableCell,
	DynamicTableData,
	GraphNodeData,
} from '../types/interfaces';
import { useContext, useEffect, useMemo, useState } from 'react';
import { StoreContext } from '../store/context';
import AccordionProductsVendorsPerCountry from 'vna/components/accordion/children/AccordionProductsVendorsPerCountry';
import AccordionVendorsCountriesPerProduct from 'vna/components/accordion/children/AccordionVendorsCountriesPerProduct';

function HomePage() {
	const store = useContext(StoreContext);
	const [amlExplorerRows, setAmlExplorerRows] = useState<AmlExplorerRows[]>([]);
	const [networkKey, setNetworkKey] = useState(0);

	if (!store) {
		throw new Error('StoreContext is not available');
	}

	const { state } = store;

	const ptd = useMemo(() => ({ ...state.ptd }), [state.ptd]);
	const fileData = useMemo(() => ({ ...state.fileData }), [state.fileData]);

	function createData(
		id: number,
		city: string,
		country: string,
		vendor: string,
		product: string
	): AmlExplorerRows {
		return {
			id,
			city,
			country,
			vendor,
			product,
		};
	}

	useEffect(() => {
		const newAmlExplorerRows: AmlExplorerRows[] = [];

		// Get AML from select input
		ptd.values?.forEach((product) => {
			const specs = product.productSpecList.filter(
				(spec) => spec.pickListItemName
			);
			product.vendorsList.forEach((vendor) => {
				newAmlExplorerRows.push(
					createData(
						newAmlExplorerRows.length + 1,
						vendor.city,
						vendor.country,
						vendor.vendorName,
						[
							product.name,
							...specs.map((spec) => spec.pickListItemName),
						].join(' | ')
					)
				);
			});
			if (product.vendorsList.length === 0) {
				newAmlExplorerRows.push(
					createData(
						newAmlExplorerRows.length + 1,
						'',
						'',
						'',
						[
							product.name,
							...specs.map((spec) => spec.pickListItemName),
						].join(' | ')
					)
				);
			}
		});

		// Get AML from file input
		fileData.values?.forEach((field) => {
			newAmlExplorerRows.push(
				createData(
					newAmlExplorerRows.length + 1,
					'',
					field['Vendor Location'],
					field.Vendor,
					[field.Category, field.Material, field.Subcategory].join(' | ')
				)
			);
		});

		setAmlExplorerRows(newAmlExplorerRows);
	}, [ptd, fileData]);

	const amlExplorerColumns: readonly DynamicTableCell[] = [
		{
			id: 'country',
			numeric: false,
			disablePadding: true,
			label: 'Country',
		},
		{
			id: 'vendor',
			numeric: false,
			disablePadding: false,
			label: 'Vendor',
		},
		{
			id: 'product',
			numeric: false,
			disablePadding: false,
			label: 'Product',
		},
	];

	///////////// Vendors PerCountry //////////////////////
	const vendorsPerCountry: Partial<Record<string, Set<string>>> = {};

	// Group by country and use a Set to store unique vendor names
	amlExplorerRows.forEach((row: AmlExplorerRows) => {
		if (!vendorsPerCountry[row.country]) {
			vendorsPerCountry[row.country] = new Set();
		}
		vendorsPerCountry[row.country]?.add(row.vendor); // Store only the vendor name
	});

	const vendorsPerCountryArray: DynamicTableData[] = [];

	Object.keys(vendorsPerCountry).forEach((key) => {
		vendorsPerCountryArray.push({
			country: key,
			vendor: vendorsPerCountry[key]?.size || 0,
		});
	});
	vendorsPerCountryArray.sort((a, b) => {
		const vendorA = a.vendor as number;
		const vendorB = b.vendor as number;
		return vendorB - vendorA;
	});
	//////////// Vendors Per Country end //////////////////

	///////////// Products Per Country ////////////////////
	const productsPerCountry: Partial<Record<string, Set<string>>> = {};

	// Group by country and use a Set to store unique product names
	amlExplorerRows.forEach((row: AmlExplorerRows) => {
		if (!productsPerCountry[row.country]) {
			productsPerCountry[row.country] = new Set();
		}
		productsPerCountry[row.country]?.add(row.product); // Store only the product name
	});

	const productsPerCountryArray: DynamicTableData[] = [];

	Object.keys(productsPerCountry).forEach((key) => {
		productsPerCountryArray.push({
			country: key,
			product: productsPerCountry[key]?.size || 0, // Use size for unique product count
		});
	});
	productsPerCountryArray.sort((a, b) => {
		const productA = a.product as number;
		const productB = b.product as number;
		return productB - productA;
	});
	///////////// Products Per Country end ////////////////////

	///////////// Vendors Per Product ////////////////////
	const vendorsPerProduct: Partial<Record<string, Set<string>>> = {};

	// Group by product and use a Set to store unique vendor names
	amlExplorerRows.forEach((row: AmlExplorerRows) => {
		if (!vendorsPerProduct[row.product]) {
			vendorsPerProduct[row.product] = new Set();
		}
		vendorsPerProduct[row.product]?.add(row.vendor); // Store only the vendor name
	});

	const vendorsPerProductArray: DynamicTableData[] = [];

	Object.keys(vendorsPerProduct).forEach((key) => {
		vendorsPerProductArray.push({
			product: key,
			vendor: vendorsPerProduct[key]?.size || 0, // Use size for unique vendor count
		});
	});
	vendorsPerProductArray.sort((a, b) => {
		const vendorA = a.vendor as number;
		const vendorB = b.vendor as number;
		return vendorB - vendorA;
	});
	///////////// Vendors Per Product end /////////////////

	///////////// CountriesPerProduct /////////////////
	const countriesPerProduct: Partial<Record<string, Set<string>>> = {};

	// Group by product and use a Set to store unique country names
	amlExplorerRows.forEach((row: AmlExplorerRows) => {
		if (!countriesPerProduct[row.product]) {
			countriesPerProduct[row.product] = new Set();
		}
		countriesPerProduct[row.product]?.add(`${row.city ? row.city + ', ' : ''} ${row.country}`);
	});

	const countriesPerProductArray: DynamicTableData[] = [];

	Object.keys(countriesPerProduct).forEach((key) => {
		countriesPerProductArray.push({
			product: key,
			country: countriesPerProduct[key]?.size || 0, // Use size for unique country count
		});
	});

	countriesPerProductArray.sort((a, b) => {
		const countryA = a.country as number;
		const countryB = b.country as number;
		return countryB - countryA;
	});
	///////////// CountriesPerProduct end /////////////

	const dataForNetworkGraph: {
		nodes: {
			parent: string;
			children: GraphNodeData[];
		}[];
	} | null =
		ptd.values && ptd.values.length > 0
			? {
				nodes: [
					{
						parent: ptd.name ?? '',
						children: ptd.values?.flatMap((product) => {
							const specs = product.productSpecList.filter(
								(spec) => spec.pickListItemName
							);
							const productVendors = product.vendorsList.filter(
								(vendor) => vendor.productId === product.id
							);
							return productVendors && productVendors.length > 0
								? productVendors.map((vendor) => {
									return {
										product: [
											product.name,
											...specs.map((spec) => spec.pickListItemName),
										].join(' | '),
										vendor: vendor.vendorName,
										location: vendor.country,
									  };})
								: [
									{
										product: [
											product.name,
											...specs.map((spec) => spec.pickListItemName),
										].join(' | '),
										vendor: '',
										location: '',
									},
									  ];
						}),
					},
				],
			  }
			: fileData.values && fileData.values.length > 0
				? {
					nodes: [
						{
							parent: fileData?.name ?? '',
							children: fileData?.values.map((field) => {
								let productName = field.Category || '';
								if (field.Material) {
									productName = productName.concat(' | ' + field.Material);
								}
								if (field.Subcategory) {
									productName = productName.concat(' | ' + field.Subcategory);
								}
								return {
									product: productName,
									vendor: field.Vendor,
									location: field['Vendor Location'],
								};
							}),
						},
					],
			  }
				: null;

	return (
		<main className="py-5 px-3 px-lg-1 px-xl-5 h-100 w-100">
			<GeneralStats aml={amlExplorerRows} />

			<Accordion label="Statistics">
				<GroupedByStats
					vendorsPerCountry={vendorsPerCountryArray}
					productsPerCountry={productsPerCountryArray}
					vendorsPerProduct={vendorsPerProductArray}
					countriesPerProduct={countriesPerProductArray}
				/>
			</Accordion>

			<Accordion label="Network Graph" onClick={() => {
				 setTimeout(() => {
					setNetworkKey(prevKey => prevKey + 1); // Change the key to force a re-render
				  });
			}}>
				<NetworkGraph key={networkKey} graph={state.graph} data={dataForNetworkGraph} />
			</Accordion>

			<Accordion label="Flat Vendor List">
				<EnhancedTable
					rows={
						amlExplorerRows.map(({ id, product, vendor, country }) => ({
							id,
							country,
							vendor,
							product,
						})) as unknown as DynamicTableData[]
					}
					columns={amlExplorerColumns}
					defaultRowsPerPage={50}
				/>
			</Accordion>

			<AccordionProductsVendorsPerCountry
				vendorsPerCountryArray={vendorsPerCountryArray}
				productsPerCountryArray={productsPerCountryArray}
			/>

			<AccordionVendorsCountriesPerProduct
				countriesPerProductArray={countriesPerProductArray}
				vendorsPerProductArray={vendorsPerProductArray}
			/>
		</main>
	);
}

export default HomePage;
