import React from 'react';
import { FormGroup } from 'components/core';
import { Controller, useFormContext } from 'react-hook-form';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import { StylesConfig } from 'react-select';

interface IProps {
	rowIndex: number;
	value?: string;
	code: ProductSpecDTO;
	isMulti?: boolean;
	hideSelectedOptions?: boolean;
	customSyle?: StylesConfig;
	editing: boolean;
}

export const DropDown = React.memo(({ rowIndex, code, isMulti = false, hideSelectedOptions, customSyle, editing }: IProps) => {
	const methods = useFormContext();

	return (
		<Controller
			key={`${code.productSpecCode}-${rowIndex}`}
			control={methods.control}

			rules={{
				required: 'This is a required field.',
			}}
			name={`specs.${code.productSpecCode}`}
			render={({ field, fieldState }) => (
				<FormGroup field={field} fieldState={fieldState}>
					<PickListSelect
						id="select"
						name={field.name}
						value={field.value}
						pickListTypeName={code.productSpecCode ?? ''}
						placeholder=""
						additionalOptions={[{ value: 'any', label: 'Any' }]}
						onChange={field.onChange}
						onMenuOpen={methods.clearErrors}
						className="mb-n3 pb-n2 me-2 mt-2 table-select dropdown-select"
						isMulti={isMulti}
						hideSelectedOptions={hideSelectedOptions}
						customSyle={customSyle}
						editing={editing}
					/>
				</FormGroup>
			)}
		/>
	);
});
