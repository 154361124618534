import React from 'react';

enum VENDOR_VALIDATION_BACKGROUND {
	GREY = '#978F8A',
	YELLOW = '#F3BA27',
	BLUE = '#34969C',
	GREEN = '#289E7B',
};

// enum VENDOR_VALIDATION_COLORS {
// 	GREY = '#4C4845',
// 	YELLOW = '#614A10',
// 	BLUE = '#153C3E',
// 	GREEN = '#103F31',
// };

interface ValidationCircleProps {
	value: number;
}


function ValidationCircle({ value }: ValidationCircleProps) {
	return (
		<span className="validation-circle" style={{
			backgroundColor: value <= 24 ? VENDOR_VALIDATION_BACKGROUND.GREY
				: value > 25 && value <= 50 ? VENDOR_VALIDATION_BACKGROUND.YELLOW
					: value > 51 && value <= 75 ? VENDOR_VALIDATION_BACKGROUND.BLUE
						: VENDOR_VALIDATION_BACKGROUND.GREEN,
			// color: value <= 24 ? VENDOR_VALIDATION_COLORS.GREY
			// 	: value > 25 && value <= 50 ? VENDOR_VALIDATION_COLORS.YELLOW
			// 		: value > 51 && value <= 75 ? VENDOR_VALIDATION_COLORS.BLUE
			// 			: VENDOR_VALIDATION_COLORS.GREEN,
		}}>
		</span>
	);
}

export default ValidationCircle;
