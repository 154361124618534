import React from 'react';

interface IState {
	currentUser: CurrentUserDTO,
	currentUniqueId: string,
	companyProfile: OrganizationDTO | null,
	settings: any,
	isInitializing: boolean,
	refreshUser: (OrganizationId?: number) => Promise<boolean>,
	getCompanyProfile: (signal?: AbortSignal) => Promise<void>,
}

const initialState: IState = {
	currentUser: {} as CurrentUserDTO,
	currentUniqueId: '',
	companyProfile: null,
	settings: {} as any,
	isInitializing: true,
	refreshUser: async () => true,
	getCompanyProfile: async () => {},

};
const AuthContext = React.createContext(initialState);

export default AuthContext;