import axios from 'axios';
import { toast } from 'react-toastify';

// Get customer subscription data
const GetSubscriptionCustomer = async (signal?: AbortSignal) => {
	return axios
		.get(`${apiUrl}/subscription/customer`, {
			signal: signal
		})
		.then((data: { data: StripeCustomer }) => {
			return data.data;
		})
		.catch((err: any) => {
			toast.error(err?.response?.data?.responseStatus?.message || 'An error occurred while posting customer subscription data.');
			return null;
		});
};

const apiUrl = process.env.REACT_APP_API_URL;
const SubscriptionAPIs = {
	GetSubscriptionCustomer,
};

export default SubscriptionAPIs;