import React from 'react';
import { Tooltip } from '@mui/material';

interface IProps {
	tooltip?: string;
}
export const Text = (props: React.PropsWithChildren<IProps>) => {
	return (
		<Tooltip title={props.tooltip}>
			<span>
				{props.children}
			</span>
		</Tooltip>
	);
};