import React from 'react';
import { ReactComponent as IconProductCatalog } from 'assets/icons/icon-product-catalog.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon-arrow-right-primary.svg';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { Button } from 'components/core';
import { isString } from 'lodash';


interface IProps {
	orgProducts?: ProductSummaryDTO[];
	showSeeDetails?: boolean;
	showUpdateCatalog?: boolean;
}

const OrgProductCatalogCard = ({orgProducts, showSeeDetails, showUpdateCatalog = true}: IProps) => {
	return (
		<div className="card shadow p-3 mb-2 rounded">
			<div className="d-flex align-items-end mb-4">
				<div className="d-flex align-items-center">
					<div className="rounded-circle bg-secondary-100 p-2 me-3">
						<IconProductCatalog />
					</div>
					<h5 className="mb-0">Product Catalog</h5>
				</div>
				{showSeeDetails === true &&
					<div className="ms-auto">
						<Button className="text-primary" to="/company-profile/product-catalog">
							See Details<IconArrowRight className="ms-2" />
						</Button>
					</div>
				}
			</div>
			<div className="container-fluid px-0">
				<div className="row">
					{orgProducts && orgProducts.length > 0 ? (
						<React.Fragment>
							{
								orgProducts.map((products, index) => (

									<React.Fragment key={`product-${products.productTypeCode}-${index}`}>
										<div
											className="col-6"
										>
											<div className="d-flex">
												<p className="mb-0">
													{products.productTypeCode}
												</p>
												<p className="text-gray-600 fst-italic ms-auto mb-0">
													{products.numberOfProductsInType} {products.numberOfProductsInType === 1 ? 'Product' : 'Products'}
												</p>
											</div>
											<hr />
											{products?.productSpec?.map((c, index) => {
												return <div className="row ms-2" key={`product-${index}`}>
													<div className="col-11 offset-1">
														<div className="d-flex">
															<p className="mb-0">
																{Object.entries(c.productSpec).map(([k, v]) => {
																	if (isString(v)) {
																		if (v === '') {
																			return `${k}: Any`;
																		} else {
																			return v;
																		}
																	}
																}).join(' | ')}
															</p>
														</div>
														<hr />
													</div>
												</div>;
											})}
										</div>
									</React.Fragment>
								))
							}
						</React.Fragment>
					) : (
						<React.Fragment>
							<p className="text-gray-600 fst-italic">No products added yet</p>
							{showUpdateCatalog && <Button className="btn-primary flex-basis-content ms-3" to="/company-profile/product-catalog">
								Update Product Catalog<IconEdit className="ms-2" />
							</Button>}
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default OrgProductCatalogCard;