import React from 'react';
import { Button, LoadingPage } from 'components/core';
import { useSubscriptionContext } from 'context/Subscriptions/SubscriptionsContext';
import ChangeSubscriptionModal from 'screens/Settings/components/modals/ChangeSubscriptionModal';
import { capitalizeFirstLetter } from 'components/helperfunctions';


interface AccountBillingProps {}



function AccountBilling({}: AccountBillingProps) {
	const [showChangeSubscriptionModal, setShowChangeSubscriptionModal] = React.useState<boolean>(false);

	const { customer, loadingCustomer } = useSubscriptionContext();

	const stripeURL = `https://billing.stripe.com/p/login/test_28odTK2T84EbbqUeUU?prefilled_email=${customer?.customerEmail}`;

	const openChangeSubscriptionModal = () => {
		setShowChangeSubscriptionModal(true);
	};

	const formatDate = (date: string) => {
		return new Date(date).toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	};

	function getNextBillingDate(subscription: Subscription) {
		switch (subscription?.status) {
		  case 'trialing':
		  case 'active':
		  case 'past_due':
			return formatDate(subscription?.currentPeriodEnd); // Show next billing date

		  case 'canceled':
		  case 'unpaid':
		  case 'paused':
		  case 'incomplete':
			return capitalizeFirstLetter(subscription?.status); // No future billing date
		  case 'incomplete_expired':
			return 'Incomplete expired'; // No future billing date

		  default:
			return null; // Handle unexpected values
		}
	}

	if (loadingCustomer) {
		return <LoadingPage />;
	}

	return (
		<div className="row mt-4">
			<div className="col-12">
				<div className="card-outlined-header">
					<div className="card-title">Current Plan</div>
					<div>
						<Button className="card-sm-btn" onClick={openChangeSubscriptionModal}>
							Change Plan
						</Button>
					</div>
				</div>
				{customer?.subscriptions?.map((subscription, i) => (
					<div className={`card ${i < customer.subscriptions.length - 1 && 'mb-3'}`} key={subscription.id}>
						<div className="card-body">
							<h5>{customer.subscriptionType} Plan</h5>
							<div className="d-flex justify-content-between">
								<div className="me-3">
									<div className="fw-bold">Billing Period:</div>
									<div className="fs-5 text-muted">
										{`${formatDate(subscription.currentPeriodStart)} - ${formatDate(subscription.currentPeriodEnd)}`}
									</div>
								</div>
								<div>
									<div className="fw-bold">Next Billing Payment:</div>
									<div className="fs-5 text-muted">{getNextBillingDate(subscription) || 'N/A'}</div>
								</div>
							</div>
						</div>
					</div>
				))}
				{
					!customer?.subscriptions?.length && (
						<div className="card">
							<div className="card-body">
								<h5>Free Plan</h5>
								<div className="d-flex justify-content-between">
									<div className="me-3">
										<div className="fw-bold">Billing Period:</div>
										<small className="text-muted">N/A</small>
									</div>
									<div>
										<div className="fw-bold">Next Billing Payment:</div>
										<small className="text-muted">N/A</small>
									</div>
								</div>
							</div>
						</div>
					)
				}
				<div className="card-outlined-footer">
					<a href={stripeURL} target="_blank" rel="noreferrer" className="card-link">
						View Billing Details and Manage Subscription
					</a>
				</div>
			</div>


			{showChangeSubscriptionModal && <ChangeSubscriptionModal
				isOpen={showChangeSubscriptionModal}
				onRequestClose={() => setShowChangeSubscriptionModal(false)}
			/>}
		</div>
	);
}

export default AccountBilling;
