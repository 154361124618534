import { LoadingPage, Page } from 'components/core';
import { usePTD } from 'context/PTD/PTDContext';
import React from 'react';
import { useParams } from 'react-router-dom';
import TitleBar from 'screens/Components/TitleBar';
import PremiumTradeDirectoryListsByVendors from 'screens/PremiumTradeDirectory/listsByView/PremiumTradeDirectoryListsByVendors';
import { getEnumFromVendorType } from 'utils/utils';


const PremiumTradeDirectoryTypeOverview = () => {
	const params = useParams();
	const id = params.id;

	const {
		getSinglePTD,
		isInitializingPTD,
	} = usePTD();
	const PTD: PTD | undefined = id ? getSinglePTD(id) : undefined;

	const pageTitle = PTD ? `${PTD.name}` : 'Single Trade Directory';
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		setIsLoading(isInitializingPTD);
	}, [isInitializingPTD]);

	if (isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar pageTitle={pageTitle} />
				</div>
			</div>
			<div className="container-fluid mb-2 px-5 py-3">
				<div className="row my-4">
					<div className="col-12">
						{PTD &&
							<PremiumTradeDirectoryListsByVendors
								vendorType={getEnumFromVendorType(PTD.vendorType)}
							/>
						}
					</div>
				</div>
			</div>
		</Page>
	);
};

export default PremiumTradeDirectoryTypeOverview;

