import React from 'react';
import { ReactComponent as IconCertification } from 'assets/icons/icon-certification.svg';
import { ReactComponent as IconVerifiedConnection } from 'assets/icons/icon-verified-connection.svg';
import { sortConfirmedOrganizations, sortUnconfirmedOrganizations } from 'utils/utils';
import { IconCircleTick } from 'assets/icons';

interface IProps {
	organizationList?: LinkedOrganizationDTO[];
	orgType: OrganizationTypeEnum | undefined;
	title: string | null;
	orgName?: string;
	showExplanation?: boolean;
	isAML?: boolean;
}

const LinkedOrganizationCard = (props: IProps) => {
	if (props.organizationList === undefined || props.organizationList.length === 0) {
		return <React.Fragment />;
	}

	const sortedConfirmedOrganizationList = sortConfirmedOrganizations(props.organizationList);
	const sortedOrganizationList = sortUnconfirmedOrganizations(props.organizationList);


	return (
		<div className="card p-3 shadow p-0 mb-3 rounded">
			<div className="card-body">
				<div className="d-flex align-items-center mb-3">
					<div className="rounded-circle bg-secondary-100 p-2 me-3">
						<IconCertification />
					</div>
					<h5 className="mb-0">{props.title}</h5>
				</div>
				{props.isAML ?
					(<p className="mb-4 fs-16 fst-italic text-gray-700 d-flex align-items-center">
						<IconCircleTick className="mx-2" />
						{props.orgType === 'manufacturer' ? `Denotes ${props.orgName} is on the owner's AML` : `Denotes manufacturing company has confirmed the connection with ${props.orgName}`}

					</p>)
					:
					(<p className="mb-4 fs-16 fst-italic text-gray-700 d-flex align-items-center">
						<IconCircleTick className="mx-2" />Denotes companies that have indicated working with {props.orgName}.
					</p>
					)
				}
				<div className="container-fluid px-0">
					<div className="row">
						{sortedConfirmedOrganizationList.map((item) => (
							<div className="col-6" key={`verifiedorgworkwith-${item.id}`}>
								<div className="border-bottom border-gray-200 p-2 d-flex align-items-center">
									<IconVerifiedConnection className="me-2 smallIcon" />
									<span className="d-inline-block text-truncate" title={item.organizationName}>
										{item.organizationName}
									</span>
								</div>
							</div>
						))
						}
						{sortedOrganizationList.map((item) => (
							<div className="col-6" key={`unverifiedorgworkwith-${item.id}`}>
								<div className="border-bottom border-gray-200 p-2 d-flex align-items-center">
									<IconCertification className="me-2 smallIcon" />
									<span className="d-inline-block text-truncate" title={item.organizationName}>
										{item.organizationName}
									</span>
								</div>
							</div>
						))
						}
					</div>
				</div>
			</div>
		</div >
	);
};


export default LinkedOrganizationCard;

