import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

//#region component imports
import { LoadingPage } from './components/core';
import FourOhFourPage from './screens/404Page';
import FourOhOnePage from './screens/401Page';

import LandingPage from './screens/LandingPage';

import TenantListPage from './admin/Tenant/TenantListingPage';
import TenantDetailsPage from './admin/Tenant/TenantDetailsPage';
import TenantUserListPage from './admin/Users/TenantUserListPage';
import TenantUserDetailsPage from './admin/Users/TenantUserDetailsPage';

import RoleListingPage from './admin/Authorization/RoleListingPage';
import RoleDetailsPage from './admin/Authorization/RoleDetailsPage';

import PickListTypeListPage from './admin/PickList/PickListTypeListPage';
import PickListItemListPage from './admin/PickList/PickListItemListPage';

import PickListTypeDetails from './admin/PickList/PickListTypeDetails';
import PickListItemDetails from './admin/PickList/PickListItemDetails';

//#endregion

import { Permissions, UserRole } from './auth/Permissions';
import UpdateUserInfo from 'screens/UpdateUserInfo';
import UpdateUserPassword from 'screens/UpdateUserPassword';
import AuthContext from 'context/Auth/AuthContext';
import FeedbackList from 'punchcardcore/feedback/FeedbackList';
import FeedbackDetails from 'punchcardcore/feedback/FeedbackDetails';
import FeedbackForm from 'punchcardcore/feedback/FeedbackForm';
import { EventEmitter } from 'events';
import { emitAppHistoryEvent } from 'punchcardcore/utils/useAppHistoryTracker';
import RequiresPermissions from 'context/RequiresPermissions';
import WhatCompanyAreYou from 'screens/OnBoarding/WhatCompanyAreYou';
import WhichIndustriesSectors from 'screens/OnBoarding/WhichIndustriesSectors';
import LocationOfOffice from 'screens/OnBoarding/LocationOfOffice';
import ContactInfo from 'screens/OnBoarding/ContactInfo';
import Locations from 'screens/OnBoarding/Locations';
import ShippingLocations from 'screens/OnBoarding/ShippingLocations';
import CompanyValues from 'screens/OnBoarding/CompanyValues';
import WhoDoYouWorkWith from 'screens/OnBoarding/WhoDoYouWorkWith';
import OnBoardingSuccess from 'screens/OnBoarding/OnBoardingSuccess';
import AdditionDetails from 'screens/OnBoarding/AdditionalDetails';
import FormProvider from 'screens/OnBoarding/FormContext';
import Requests from 'screens/Requests/Requests';
import NewRequest from 'screens/Requests/NewRequest';
import SideBar from 'screens/Components/SideBar';
import ProductCatalog from 'screens/ProductCatalog/ProductCatalog';
import ScopeMatchLogo from 'screens/OnBoarding/components/Logo';
import SuggestedMatches from 'screens/Requests/SuggestedMatches';
import MatchDetails from 'screens/Requests/MatchDetails';
import RequestProvider from 'screens/Requests/RequestContext';
import MatchHistory from 'screens/Matches/MatchHistory';
import CompanyProfile from 'screens/CompanyProfile/CompanyProfile';
import UpdateProfile from 'screens/CompanyProfile/UpdateProfile';
import ImportData from 'admin/Import/ImportData';
import ExportData from 'admin/Import/ExportData';
import OrganizationList from 'admin/Organization/OrganizationList';
import WelcomePage from 'screens/OnBoarding/WelcomePage';
import Redirect from 'components/Redirect';
import ExportVisualization from 'admin/DataVisualization/ExportVisualization';
import PickListProductTypes from 'admin/PickList/PickListProductTypes';
import Dashboard from 'screens/Dashboard/Dashboard';
import PickListProductSpecs from 'admin/PickList/PickListProductSpecs';
import PremiumTradeDirectoriesOverview from 'screens/PremiumTradeDirectory/PremiumTradeDirectoriesOverview';
import PremiumTradeDirectoryDetails from 'screens/PremiumTradeDirectory/PremiumTradeDirectoryTypeOverview';
import PremiumTradeDirectoryListDetails from 'screens/PremiumTradeDirectory/PremiumTradeDirectoryListOverview';
import PTDAccessOverview from 'screens/PremiumTradeDirectory/PTDAccessOverview';
import PTDVendorsOverview from 'screens/PremiumTradeDirectory/PTDVendorsOverview';
import RequiresRole from 'context/RequiresRole';
import RequiresCreator from 'context/RequiresCreator';
import News from 'screens/News/News';
import AppVNA from './vna/AppVNA';
import PTDProductsOverview from 'screens/PremiumTradeDirectory/PTDProductsOverview';
import Settings from 'screens/Settings/Settings';
import { SubscriptionProvider } from 'context/Subscriptions/SubscriptionsContext';

export const eventEmitter = new EventEmitter();

const AppNavigator = () => {
	const location = useLocation();
	const authCxt = React.useContext(AuthContext);
	React.useEffect(() => {
		emitAppHistoryEvent(location.pathname);
	}, [location.pathname]);
	const onboardingComplete = authCxt.currentUser.onboardingComplete;
	const orgType = authCxt.currentUser.organizationType;
	const { settings } = React.useContext(AuthContext);
	const pathnames = ['searchresults', 'matchdetails'];
	const hideSidebar =
		location.pathname.includes('invite') || location.pathname.includes('vna');

	if (onboardingComplete === false) {
		return (
			/* OnBoarding Screens */
			<FormProvider>
				<ScopeMatchLogo />
				<Routes>
					<Route path="/" element={<LandingPage />} />
					<Route path="what-company-are-you" element={<WhatCompanyAreYou />} />
					<Route
						path="which-industries-and-sectors"
						element={<WhichIndustriesSectors />}
					/>
					<Route path="contact-information" element={<ContactInfo />} />
					<Route path="location-of-office" element={<LocationOfOffice />} />
					<Route path="locations" element={<Locations />} />
					<Route path="shipping-locations" element={<ShippingLocations />} />
					<Route path="company-values" element={<CompanyValues />} />
					<Route path="who-do-you-work-with" element={<WhoDoYouWorkWith />} />
					<Route path="additional-details" element={<AdditionDetails />} />
					<Route path="onboarding-success" element={<OnBoardingSuccess />} />

					{/* catch all for any route that doesnt match the above */}
					<Route path="*" element={<Redirect to="/" />} />
				</Routes>
			</FormProvider>
		);
	}

	return (
		<RequestProvider>
			<div className="d-flex overflow-hidden vna-overflow">
				{!hideSidebar && (
					<SideBar
						sidebarExpanded={pathnames.every(
							(name) => !location.pathname.includes(name)
						)}
					/>
				)}
				<Routes>
					<Route path="/" element={<Dashboard />} />
					<Route
						path="/vna"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithPTDFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<AppVNA />
							</RequiresRole>
						}
					/>
					<Route path="invite" element={<WelcomePage />} />
					<Route path="company-profile" element={<CompanyProfile />} />
					<Route
						path="company-profile/product-catalog"
						element={
							orgType !== 'owner' ? <ProductCatalog /> : <FourOhFourPage />
						}
					/>
					<Route
						path="company-profile/update-profile"
						element={<UpdateProfile />}
					/>
					<Route
						path="requests"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithRequestFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<Requests />
							</RequiresRole>
						}
					/>
					<Route
						path="requests/:requestID"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithRequestFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<NewRequest />
							</RequiresRole>
						}
					/>
					<Route
						path="requests/searchresults/:requestID"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithRequestFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<SuggestedMatches />
							</RequiresRole>
						}
					/>
					<Route
						path="requests/searchresults/:requestID/matchdetails/:orgID"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithRequestFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<MatchDetails />
							</RequiresRole>
						}
					/>
					<Route
						path="match-history"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithRequestFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<MatchHistory />
							</RequiresRole>
						}
					/>
					<Route
						path="premium-trade-directories"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithPTDFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<PremiumTradeDirectoriesOverview />
							</RequiresRole>
						}
					/>
					<Route
						path="premium-trade-directories/:id"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithPTDFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<PremiumTradeDirectoryDetails />
							</RequiresRole>
						}
					/>
					<Route
						path="premium-trade-directories/:id/:listName/:idPTDList"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithPTDFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<PremiumTradeDirectoryListDetails />
							</RequiresRole>
						}
					/>
					<Route
						path="premium-trade-directories/access/:id/:listName/:idPTDList"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithPTDFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<RequiresCreator>
									<PTDAccessOverview />
								</RequiresCreator>
							</RequiresRole>
						}
					/>
					<Route
						path="premium-trade-directories/products/:id/:listName/:idPTDList"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithPTDFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<PTDProductsOverview />
							</RequiresRole>
						}
					/>
					<Route
						path="premium-trade-directories/vendors/:id/:listName/:idPTDList"
						element={
							<RequiresRole
								requiredRoles={[
									UserRole.ProfileWithPTDFeature,
									UserRole.ProfileWithPTDAndRequestsFeatures,
								]}
							>
								<PTDVendorsOverview />
							</RequiresRole>
						}
					/>
					<Route path="news" element={<News />} />
					<Route path="401" element={<FourOhOnePage />} />
					<Route path="404" element={<FourOhFourPage />} />
					<Route path="AzureADB2C/Account/SignOut" element={<LoadingPage />} />
					<Route path="admin">
						<Route
							path="picklistproducttypes"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.WriteTenant,
									]}
								>
									<PickListProductTypes />
								</RequiresPermissions>
							}
						/>
						<Route
							path="picklistproductspecs/:productTypeID"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.WriteTenant,
									]}
								>
									<PickListProductSpecs />
								</RequiresPermissions>
							}
						/>
						<Route
							path="importdata"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.WriteTenant,
									]}
								>
									<ImportData />
								</RequiresPermissions>
							}
						/>
						<Route
							path="datavisualization"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.WriteTenant,
									]}
								>
									<ExportVisualization />
								</RequiresPermissions>
							}
						/>
						<Route
							path="organizationlist"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.WriteTenant,
									]}
								>
									<OrganizationList />
								</RequiresPermissions>
							}
						/>
						<Route
							path="exportdata"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.WriteTenant,
									]}
								>
									<ExportData />
								</RequiresPermissions>
							}
						/>
						<Route
							path="tenants"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.WriteTenant,
									]}
								>
									<TenantListPage />
								</RequiresPermissions>
							}
						/>
						<Route
							path="tenants/:tenantId"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.WriteTenant,
									]}
								>
									<TenantDetailsPage />
								</RequiresPermissions>
							}
						/>
						<Route
							path="manage-users"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadUser,
										Permissions.WriteUser,
									]}
								>
									<TenantUserListPage />
								</RequiresPermissions>
							}
						/>
						<Route
							path="manage-users/:tenantId/user-details/:userId"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadUser,
										Permissions.WriteUser,
									]}
								>
									<TenantUserDetailsPage />
								</RequiresPermissions>
							}
						/>
						<Route
							path="roles"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadRole,
										Permissions.WriteRole,
									]}
								>
									<RoleListingPage />
								</RequiresPermissions>
							}
						/>
						<Route
							path="roles/:roleId"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadRole,
										Permissions.WriteRole,
									]}
								>
									<RoleDetailsPage />
								</RequiresPermissions>
							}
						/>
					</Route>
					<Route
						path="pick-lists"
						element={
							<RequiresPermissions
								requiredPermissions={[
									Permissions.ReadPickList,
									Permissions.WritePickList,
								]}
							>
								<PickListTypeListPage />
							</RequiresPermissions>
						}
					/>
					<Route
						path="pick-lists/:pickListTypeId"
						element={
							<RequiresPermissions
								requiredPermissions={[
									Permissions.ReadPickList,
									Permissions.WritePickList,
								]}
							>
								<PickListTypeDetails />
							</RequiresPermissions>
						}
					/>
					<Route
						path="pick-lists/:pickListTypeId/pick-list-items"
						element={
							<RequiresPermissions
								requiredPermissions={[
									Permissions.ReadPickList,
									Permissions.WritePickList,
								]}
							>
								<PickListItemListPage />
							</RequiresPermissions>
						}
					/>
					<Route
						path="pick-lists/:pickListTypeId/pick-list-items/:pickListItemId"
						element={
							<RequiresPermissions
								requiredPermissions={[
									Permissions.ReadPickList,
									Permissions.WritePickList,
								]}
							>
								<PickListItemDetails />
							</RequiresPermissions>
						}
					/>

					<Route path="settings">
						<Route path="update-info" element={<UpdateUserInfo />} />
						{settings.AllowUserPasswordChange && (
							<Route path="update-password" element={<UpdateUserPassword />} />
						)}
						<Route
							path="manage-subscriptions"
							element={
								<SubscriptionProvider>
									<Settings/>
								</SubscriptionProvider>
							}
						/>
					</Route>
					<Route path="feedback">
						<Route path="" element={<FeedbackForm />} />
						<Route
							path="feedbackList"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.ReadPickList,
										Permissions.ReadRole,
									]}
								>
									<FeedbackList />
								</RequiresPermissions>
							}
						></Route>
						<Route
							path=":feedbackId"
							element={
								<RequiresPermissions
									requiredPermissions={[
										Permissions.ReadTenant,
										Permissions.ReadPickList,
										Permissions.ReadRole,
									]}
								>
									<FeedbackDetails />
								</RequiresPermissions>
							}
						></Route>
					</Route>
					<Route path="*" element={<FourOhFourPage />} />
				</Routes>
			</div>
		</RequestProvider>
	);
};

export default AppNavigator;
