import axios, { isAxiosError } from 'axios';
import { PremiumTradeDirectory } from '../types/interfaces';

const apiUrl = process.env.REACT_APP_API_URL;

const ptdAPI = {
	GetPremiumTradeDirectories: async (signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/premium-trade-directory`, {
			signal: signal
		})
			.then((data: { data: PremiumTradeDirectory[] }) => {
				return data.data;
			})
			.catch((err: unknown) => {
				if (isAxiosError(err)) {
					console.error({ error: err.response?.data.responseStatus.message });
				} else {
					console.error('An unknown error occurred.');
				}

				return null;
			});
		return response;
	},
	getVNAbyPTDs: (premiumTradeDirectoryId: number) => {
		return axios.get(`${apiUrl}/vna/premium-trade-directory/${premiumTradeDirectoryId}`, {
		})
			.then(response => response.data);
	},
};

export default ptdAPI;