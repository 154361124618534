import React, { createContext, useContext, ReactNode } from 'react';
import { useSubscriptionStore } from 'context/Subscriptions/SubscriptionsStore';

interface SubscriptionContextType {
	customer: StripeCustomer | null;
	loadingCustomer: boolean;
}

const SubscriptionContext = createContext<
SubscriptionContextType | undefined
>(undefined);

export const SubscriptionProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const {
		customer,
		loadingCustomer,
	} = useSubscriptionStore();

	return (
		<SubscriptionContext.Provider
			value={{
				customer,
				loadingCustomer,
			}}
		>
			{children}
		</SubscriptionContext.Provider>
	);
};

export const useSubscriptionContext = () => {
	const context = useContext(SubscriptionContext);
	if (!context) {
		throw new Error(
			'useUserOrganizations must be used within a UserOrganizationsProvider'
		);
	}
	return context;
};
