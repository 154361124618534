import React, { useState } from 'react';
import { Button, DataTable } from 'components/core';
import { Buttons } from 'screens/ProductCatalog/components/columns';
import { FieldValues, useForm, UseFormSetValue } from 'react-hook-form';
import TableColumnInput from 'components/tableColumns/TableColumnInput';
import { OnboardingErrorMsg } from 'screens/OnBoarding/utils';
import { emailRegex, phoneRegex } from 'utils';
import { IconAdd } from 'assets/icons';
import DeleteModal from 'screens/ProductCatalog/components/Forms/DeleteModal';

interface UpdateContactsProps {
	setValue: UseFormSetValue<FieldValues>
	organizationContacts: OrganizationContactDTO[];
}

const UpdateContacts: React.FC<UpdateContactsProps> = ({organizationContacts, setValue}) => {
	const contactFormMethods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const [editTable, setEditTable] = useState<ContactTable | null>(null);
	const [showDeleteRowModal, setShowDeleteRowModal] = React.useState<{ id: number, name: string } | null>(null);

	const contacts = [...organizationContacts];

	// Helper Functions
	function openDeleteModal(row: any) {
		setShowDeleteRowModal({ id: row.original.id, name: row.original.contactName });
	}
	function closeDeleteRowModal() {
		setShowDeleteRowModal(null);
	}
	async function confirmedDeleteItem(val: { id: number, name: string }) {
		const updatedContacts = organizationContacts.filter(contact => contact.id !== val.id);
		setValue('organizationContacts', updatedContacts);
		return true;
	}

	const closeAddRow = () => setEditTable(null);

	const onClickEditOrDuplicate = (row: any, type: 'edit' | 'duplicate') => {
		Object.keys(row.original).forEach((key) => {
			contactFormMethods.setValue('specs.' + key, row.original[key]);
		});

		setEditTable(type === 'edit' ? { rowId: row.index, contactId: row.original.id } : {
			rowId: 0,
			contactId: 0,
			duplicateRow: row.original.id
		});
	};

	const submitForm = (fields: FieldValues) => {
		if (!!editTable) {
			if (editTable.newLine || editTable.duplicateRow) {
				if (editTable.duplicateRow) {
					fields.specs.id = null;
				}
				setValue('organizationContacts', [...organizationContacts, {
					...fields.specs,
				}]);
			} else {
				const updatedContacts = organizationContacts.map(contact =>
					contact.id === editTable.contactId ? { ...contact, ...fields.specs } : contact
				);
				setValue('organizationContacts', updatedContacts);
			}
			setEditTable(null);
			contactFormMethods.reset(
				{},
				{ keepDirty: false, keepValues: false }
			);
		}
	};

	// Table Configuration
	const columns = [
		{
			Header: 'Role',
			accessor: 'contactRole',
			filterable: true,
			Cell: (row: any) => (
				<TableColumnInput
					name="contactRole"
					control={contactFormMethods.control}
					row={row}
					editTable={editTable}
					rules={{ required: OnboardingErrorMsg.Role }}
				/>
			),
		},
		{
			Header: 'Name',
			accessor: 'contactName',
			filterable: true,
			Cell: (row: any) => (
				<TableColumnInput
					name="contactName"
					control={contactFormMethods.control}
					row={row}
					editTable={editTable}
					rules={{ required: OnboardingErrorMsg.Name }}
				/>
			),
		},
		{
			Header: 'Email',
			accessor: 'contactEmail',
			filterable: true,
			Cell: (row: any) => (
				<TableColumnInput
					name="contactEmail"
					control={contactFormMethods.control}
					row={row}
					editTable={editTable}
					rules={{
						required: OnboardingErrorMsg.Email,
						pattern: {
							value: emailRegex,
							message: OnboardingErrorMsg.EmailPattern,
						},
					}}
				/>
			),
		},
		{
			Header: 'Phone',
			accessor: 'contactPhone',
			filterable: true,
			Cell: (row: any) => (
				<TableColumnInput
					name="contactPhone"
					control={contactFormMethods.control}
					row={row}
					editTable={editTable}
					rules={{
						required: OnboardingErrorMsg.PhoneNumber,
						pattern: {
							value: phoneRegex,
							message: OnboardingErrorMsg.PhoneNumberPattern,
						},
					}}
				/>
			),
		},
		{
			Header: '',
			accessor: 'actions',
			filterable: false,
			sortable: false,
			className: 'text-center pt-1',
			Cell: (row: any) => {
				const isEditingCurrentRow = !!editTable && (editTable.rowId === row.index || editTable?.duplicateRow === row.index);
				const rowIsDisabled = !!editTable && editTable.rowId !== row.index;

				return (
					<Buttons
						isEditing={isEditingCurrentRow}
						disabled={rowIsDisabled}
						onClickSave={contactFormMethods.handleSubmit(submitForm)}
						onClickCancel={closeAddRow}
						onClickEdit={() => onClickEditOrDuplicate(row, 'edit')}
						onClickDelete={() => openDeleteModal(row)}
						onClickDuplicate={() => onClickEditOrDuplicate(row, 'duplicate')}
						canEdit={true}
					/>
				);
			},
		},
	];

	// Add new or duplicate row
	const newRow: OrganizationContactDTO = {
		id: 0,
		organizationId: 0,
		contactRole: '',
		contactName: '',
		contactEmail: '',
		contactPhone: '',
		deleted: false,
	};

	if (editTable?.newLine) {
		contacts.unshift(newRow);
	}

	if (editTable?.duplicateRow) {
		const duplicateContact = { ...contacts.find(contact => contact.id === editTable.duplicateRow), id: 0 };
		contacts.unshift(duplicateContact);
	}

	return (
		<React.Fragment>
			<div className="bg-light border-bottom border-primary-200 px-3 py-2 d-flex align-items-center rounded-top custom-shadow">
				<Button
					onClick={() => {
						contactFormMethods.reset({}, { keepValues: false, keepDirty: false });
						setEditTable({
							rowId: 0,
							newLine: true,
						});
					}}
					disabled={!!editTable}
					className="ms-auto text-primary py-0 fs-14 fw-normal"
				>
					<IconAdd className="me-2 svg-20" />Add Contact
				</Button>
			</div>
			<DataTable
				data={contacts}
				columns={columns}
				filterable={true}
				sortable={true}
				resizable={true}
				noDataText="No data found."
			/>

			<DeleteModal
				item={showDeleteRowModal}
				deleteRow={confirmedDeleteItem}
				onRequestClose={closeDeleteRowModal}
				labels={{description: 'In order this row to permananly be deleted, please save all changes by clicking the Submit button!'}}
			/>
		</React.Fragment>
	);
};

export default UpdateContacts;