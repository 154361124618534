import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const pickListAPI = {
	getPickListItem: (itemId?: string) => {
		return axios.get(`${apiUrl}/pick-list-items/${itemId}`)
			.then(response => response.data as PickListItem);
	},
	getPickListItems: () => {
		return axios.get(`${apiUrl}/pick-list-items`)
			.then(response => response.data as PickListItem[]);
	},
	getPickListType: (id: string) => {
		return axios.get(`${apiUrl}/pick-list-types/${id}`)
			.then(response => response.data as PickListType);
	},
	getPickListTypes: () => {
		return axios.get(`${apiUrl}/pick-list-types`) // todo: see if axios or an interceptor can auto add the apiUrl.
			.then(response => response.data as PickListType[]);
	},
	savePickListItem: (item: PickListItem) => {
		return axios.post(`${apiUrl}/pick-list-items`, item)
			.then(response => response.data as PickListItem);
	},
	savePickListType: (type: PickListType) => {
		return axios.post(`${apiUrl}/pick-list-types`, type)
			.then(response => response.data as PickListType);
	},
	getPickListItemByType: (code: string): Promise<PickListItem[]> => {
		return axios.get(`${apiUrl}/pick-list-items/type/${code}`)
			.then(res => res.data);
	},
	getPickListCSV: () => {
		return axios.get(`${apiUrl}/pick-list-items/export?format=csv`, {
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.download = 'picklist.csv';
			link.click();
			window.URL.revokeObjectURL(url);
		})
			.catch((error) => {
				console.error('Error downloading CSV:', error);
			});
	},
};

export default pickListAPI;