import React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import SettingsStore from 'settings/SettingsStore';
// Import Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'index.scss';

// Import Bootstrap JS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

if (typeof window !== 'undefined') {
	const resizeObserverErrDiv = document.createElement('div');
	resizeObserverErrDiv.id = 'resize-observer-error';
	resizeObserverErrDiv.style.display = 'none';
	document.body.appendChild(resizeObserverErrDiv);

	const resizeObserverErr = () => {
		const errDiv = document.getElementById('resize-observer-error');
		if (errDiv) {
			errDiv.innerHTML += 'ResizeObserver loop completed with undelivered notifications.<br>';
		}
	};

	window.addEventListener('error', (event) => {
		if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
			event.stopImmediatePropagation();
			resizeObserverErr();
		}
	});
}

ReactDOM.render(
	<SettingsStore>
		<App />
	</SettingsStore>,
	document.getElementById('root') as HTMLElement
);

// service workers need more work before using in an app. (ie notification of a new version)
// registerServiceWorker();
unregister();
