import React from 'react';
import { Button, LoadingPage, Page } from 'components/core';
import ProductTypeModal from './components/Forms/ProductTypeModal';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { toast } from 'react-toastify';
import DeleteModal from './components/Forms/DeleteModal';
import TitleBar from 'screens/Components/TitleBar';
import DeleteProductTypeModal from './components/Forms/DeleteProductTypeModal';
import PickListContext from 'context/PickList/PickListContext';
import { ReactComponent as IconList } from 'assets/icons/icon-list.svg';
import { FormProvider, useForm } from 'react-hook-form';
import {
	Text,
	DropDown,
	Buttons,
} from 'screens/ProductCatalog/components/columns';
import ProductTable from 'components/scopematch/ProductTable';
import AuthContext from 'context/Auth/AuthContext';
import { StylesConfig } from 'react-select';
import { generateCombinations } from 'utils/generateCombinations';

const MAX_ROWS = 2;
const DropDownStyles: StylesConfig = {
	multiValue: (styles) => ({
		...styles,
		maxWidth: '100%',
	}),
	valueContainer: (styles) => ({
		...styles,
		display: 'flex',
		flexWrap: 'wrap',
		overflowY: 'auto',
		maxHeight: `${MAX_ROWS * 30}px`, // Assuming each row is approximately 40px high
	}),
};

const ProductCatalog = () => {
	const pageTitle = 'Product Catalog';
	const picklistCxt = React.useContext(PickListContext);
	const { currentUser, getCompanyProfile } = React.useContext(AuthContext);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	//states for showing modals
	const [showProductTypeModal, setShowProductTypeModal] =
		React.useState<boolean>(false);
	const [showDeleteRowModal, setShowDeleteRowModal] = React.useState<{
		id: number;
		name: string;
	} | null>(null);
	const [showDeleteProductTypeModal, setShowDeleteProductTypeModal] =
		React.useState<{ productTypeId: number; productTypeName: string } | null>(
			null
		);

	// state to manage which table and which row we are editing.
	const [editTable, setEditTable] = React.useState<{
		productType: string;
		rowId: number;
		newLine?: boolean;
		productId?: number;
		duplicateRow?: number;
	} | null>(null);

	// states to save the product categories and products per table
	const [productSpecsList, setProductSpecsList] =
		React.useState<ProductTypeDTO[]>();
	const [orgProductList, setOrgProductList] = React.useState<
		OrganizationProductTypeDTO[]
	>([]);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);

	// forms
	const methods = useForm<any>({ reValidateMode: 'onChange' });
	const { handleSubmit, reset, formState } = methods;

	const newRow: { [key: string]: string | JSX.Element } = {
		id: 'add-id',
		productTypeName: 'add-productTypeName',
	};
	React.useEffect(() => {
		async function fetchSpecs() {
			try {
				const productTypeListResult =
					await scopeMatchAPI.product.GetProductTypeList();
				let orgProductsResult = null;
				if (currentUser.organizationId !== undefined) {
					orgProductsResult =
					await scopeMatchAPI.organizationProduct.GetOrganizationProducts(currentUser.organizationId);
				}
				if (productTypeListResult !== null && orgProductsResult !== null) {
					setProductSpecsList(
						productTypeListResult.filter(
							(v) => v.productSpecCodes?.length !== 0
						)
					);
					setOrgProductList(orgProductsResult);
					setIsLoading(false);
				}
			} catch (error) {
				toast.error('Could not load the product catalog');
			}
		}
		fetchSpecs();
	}, [currentUser.organizationId]);

	React.useEffect(() => {}, [formState.isDirty]);

	if (isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<FormProvider {...methods}>
			<Page title={pageTitle}>
				<div className="d-flex">
					<div className="d-flex flex-column w-100">
						<TitleBar pageTitle={pageTitle} backButton={true} />
					</div>
				</div>
				<div className="container-fluid mb-5 px-5">
					<div className="row">
						<div className="col-12">
							{orgProductList.length === 0 ? (
								<div className="container-fluid px-5">
									<div className="text-center bg-light py-5 rounded">
										<IconList />
										<h5 className="mb-2">You haven’t added any products yet</h5>
										<p className="mb-2 pb-3">
											Add a product category to get started
										</p>
										<AddProductTypeButton />
									</div>
								</div>
							) : (
								<div>
									<AddProductTypeButton />
									{orgProductList.map((item, index) => {
										const { columns, data } = getTableColumns(item);

										const tableData = data || [];
										//adding an empty row to beginning of the table
										if (
											editTable?.productType === item.productTypeName &&
											editTable?.newLine === true
										) {
											tableData?.unshift(newRow);
										}
										if (!!editTable?.duplicateRow && editTable?.productType === item.productTypeName) {
											const type = editTable.productType;
											const products = orgProductList?.find((x) => x.productTypeName == type);
											const rows = products?.products?.find(
												(x) => x.id === editTable.duplicateRow
											);
											const lineItem: any = {};
											rows?.productSpecs?.forEach((x) => {
												lineItem[x.specName!] = x.code;
											});

											lineItem['id'] = 'duplicate';
											lineItem['procuctTypeName'] = rows?.productTypeName;

											tableData?.push(lineItem);
										}
										return (
											<ProductTable
												key={`table-${index}`}
												productTypeName={item.productTypeName}
												columns={columns || []}
												data={tableData}
												disabled={!!editTable}
												emptyTableTitle="Product details are empty. You must add a specific product item to save this product category to your catalog."
												onClickAddProduct={() => {
													reset({}, { keepValues: false, keepDirty: false });
													setEditTable({
														productType: item.productTypeName ?? '',
														rowId: 0,
														newLine: true,
													});
												}}
												showDeleteProductTypeModal={() =>
													setShowDeleteProductTypeModal({
														productTypeName:
															item.productTypeName === undefined
																? ''
																: item.productTypeName,
														productTypeId:
															item.productTypePickListItemId == undefined
																? 0
																: item.productTypePickListItemId,
													})
												}
											/>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
				<ProductTypeModal
					isOpen={showProductTypeModal}
					onRequestClose={closeProductTypeModal}
					addProductType={addProductType}
					productType={orgProductList.map((v) => v.productTypeName!)}
				/>

				<DeleteModal
					item={showDeleteRowModal}
					deleteRow={confirmedDeleteItem}
					onRequestClose={closeDeleteRowModal}
				/>
				<DeleteProductTypeModal
					id={showDeleteProductTypeModal}
					deleteRow={confirmedDeleteProductType}
					onRequestClose={closeDeleteProductTypeModal}
				/>
			</Page>
		</FormProvider>
	);

	function closeProductAddRow() {
		setEditTable(null);
		reset({}, { keepValues: false, keepDirty: false });
	}
	function openProductTypeModal() {
		setShowProductTypeModal(true);
	}
	function closeProductTypeModal() {
		setShowProductTypeModal(false);
	}
	async function addProductType(productType: string[] | null) {
		if (productType) {
			productType.forEach((prodType) => {
				// add any any row to new product type
				setOrgProductList((v) => [...v, { productTypeName: prodType }]);
			});
			await getCompanyProfile();
		}
	}
	function deleteRow(row: any) {
		setShowDeleteRowModal({
			id: row.original.id,
			name: row.original.productTypeName,
		});
	}
	function closeDeleteRowModal() {
		setShowDeleteRowModal(null);
	}
	function closeDeleteProductTypeModal() {
		setShowDeleteProductTypeModal(null);
	}

	async function confirmedDeleteItem(val: { id: number; name: string }) {
		try {
			let result = null;

			if (currentUser.organizationId) {
				result = await scopeMatchAPI.organizationProduct.DeleteOrganizationProduct(
					currentUser.organizationId,
					val.id
				);
			}

			if (result !== null) {
				setOrgProductList((v) => {
					const index = v.findIndex((v) => v.productTypeName === val.name);
					if (index !== -1) {
						const newArr = [...v];
						const newProducts = newArr[index].products?.filter(
							(j) => j.id !== val.id
						);
						if (newProducts?.length === 0) {
							newArr[index].products = [];
						} else {
							newArr[index].products = newProducts;
						}
						return newArr;
					}
					return v;
				});
				await getCompanyProfile();
				return true;
			}
			return false;
		} catch (error) {
			toast.error('Product could not be deleted');
			return false;
		}
	}

	async function submitForm(fields: { specs: { [x: string]: string[] } }) {
		if (formState.isDirty === false) {
			setEditTable(null);
			reset();
			return;
		}
		setIsSaving(true);
		const keys = Object.keys(fields.specs);
		const isAny = keys.map((key) => {
			const value = fields.specs[key];
			if (Array.isArray(value)) {
			  return value.some((item) => item.toLowerCase() === 'any');
			} else if (typeof value === 'string') {
			  return (value as string).toLowerCase() === 'any';
			}
			return false;
		});

		if (!isAny.includes(false)) {
			toast.error('At least one specific value is required');
			setIsSaving(false);
			return;
		}

		if (editTable?.productType === undefined) {
			toast.error('Product category title is missing');
			setIsSaving(false);
			return;
		}

		const result = await addProductToTable(
			editTable.productId ?? null,
			editTable?.productType,
			fields
		);

		if (typeof result !== 'boolean') {
			await getCompanyProfile();
			if (result.statusCode === 409) {
				toast.error(result.message ?? 'This Product already exists');
			}

			if (result.statusCode === 500) {
				toast.error(result.message ?? 'Something went wrong!');
			}
		}

		if (result === false) {
			toast.error('Something went wrong!');
		}

		await getCompanyProfile();
		setIsSaving(false);
		reset();
		setEditTable(null);
		return;
	}

	async function addProductToTable(
	  	id: number | null,
	  	productAdded: string,
	  	fields: { specs: { [x: string]: string[] | string } }
	): Promise<boolean | { message: string; statusCode: number; }> {
	  	const picklistItems = picklistCxt.pickListItems;
	  	const payload: POSTOrganizationProductDTO = {
	  		organizationId: currentUser.organizationId || 0,
	  		products: [],
	  	};
		const combinations = generateCombinations(fields.specs as { [x: string]: string[]; }, picklistItems);

		combinations.map((combo) => {
			payload.products.push({
				id: id ?? undefined,
				productTypePickListItemId: picklistItems.find(
					(v) => v.code === productAdded
				)?.id,
				productSpecs: combo
			});
		});

	  	let result: PostOrganizationProductRequest | null = null;
	  	if (currentUser.organizationId) {
	  		result = await scopeMatchAPI.organizationProduct.PostOrganizationProduct(payload, currentUser.organizationId);
	  	}

	  	if (result !== null && result !== undefined) {
	  		if (Array.isArray(result.data)) {
	  			const resultDTOs = result.data as OrganizationProductDTO[];

	  			setOrgProductList((v) => {
	  				const newArr = [...v];

	  				resultDTOs.forEach((resultDTO) => {
						const index = newArr.findIndex(
	  						(v) => v.productTypeName === resultDTO.productTypeName
						);

						if (index !== -1) {
	  						if (newArr[index].products === undefined) {
							  newArr[index].products = [];
	  						}
	  						const prodIndex = newArr[index].products?.findIndex(
							  (c) => c.id === resultDTO.id
	  						);
	  						if (
							  prodIndex !== -1 &&
							  prodIndex !== undefined &&
							  newArr[index].products !== undefined &&
							  newArr[index].products![prodIndex] !== undefined
	  						) {
							  const prodItems = newArr[index].products?.[prodIndex];
							  newArr[index].products![prodIndex] = {
							  	...prodItems,
							  	productSpecs: resultDTO.productSpecs,
							  };
	  						} else {
							  newArr[index].products?.push({
	  								productTypeName: newArr[index].productTypeName,
	  								productTypePickListItemId: resultDTO.productTypePickListItemId,
	  								productSpecs: resultDTO.productSpecs,
	  								id: resultDTO.id,
							  });
	  						}
						}
	  				});

	  				return newArr;
	  			});

	  			reset();
	  			setEditTable(null);
	  			return result.statusCode === 409 ? result : true;
	  		} else {
	  			return result;
	  		}
	  	}
	  	return false;
	  }
	  async function confirmedDeleteProductType(val: {
		productTypeId: number;
		productTypeName: string | undefined;
	}) {
	  	try {
	  		let result = null;

	  		if (currentUser.organizationId) {
	  			result = await scopeMatchAPI.organizationProduct.DeleteOrganizationProductType(
	  				currentUser.organizationId,
	  				val.productTypeId
	  			);
	  		}

	  		if (result !== null) {
	  			setOrgProductList((v) => {
	  				const newProductTypes = v.filter(
	  					(t) =>
	  						t.productTypePickListItemId !== val.productTypeId &&
							t.productTypeName !== val.productTypeName
	  				);
	  				const newArr = newProductTypes;
	  				return newArr;
	  			});
				await getCompanyProfile();
	  			toast.success(`Product category ${val.productTypeName} was deleted`);
	  			return true;
	  		}
	  		return false;
	  	} catch (error) {
	  		toast.error('Product could not be deleted');
	  		return false;
	  	}
	  }
	  function AddProductTypeButton() {
	  	return (
	  		<Button
	  			onClick={openProductTypeModal}
	  			loading={isLoading}
	  			className="btn-primary ms-auto mb-3"
	  		>
				Add Product Category
	  		</Button>
	  	);
	  }
	  function onClickEditOrDuplicate(
	  	row: any,
	  	productTypeName: string,
	  	type: 'edit' | 'duplicate'
	  ) {
	  	const keys = Object.keys(row.original);
	  	const nonProductVals = ['id', 'name', 'productTypeName', 'delete'];
	  	nonProductVals.forEach((val) => {
	  		const index = keys.indexOf(val);
	  		if (index > -1) {
	  			keys.splice(index, 1);
	  		}
	  	});
	  	keys.forEach((key) => {
	  		methods.setValue('specs.' + key, row.original[key]);
	  	});
	  	if (type === 'edit') {
	  		setEditTable({
	  			productType: productTypeName,
	  			rowId: row.index,
	  			productId: row.original.id,
	  		});
	  	} else {
	  		setEditTable({
	  			productType: productTypeName,
	  			rowId: 0,
	  			productId: 0,
	  			duplicateRow: row.original.id,
	  		});
	  	}
	  }

	  function getTableColumns(item: OrganizationProductTypeDTO) {
	  	const columns = productSpecsList
	  		?.find((v) => v.productTypeCode === item.productTypeName)
	  		?.productSpecCodes?.map((code) => ({
	  			Header: code.productSpecDescription,
	  			accessor: code.productSpecCode,
	  			Cell: (row: any) => {
	  				const isCurrentTable =
					!!editTable && editTable.productType === item.productTypeName;

	  				const isNewRow = isCurrentTable && editTable.rowId === row.index && editTable.newLine;

	  				if (
						editTable && editTable.productType === item.productTypeName &&
						editTable.rowId === row.index
	  				) {
	  					return <DropDown
							code={code}
							value={row.value || ''}
							isMulti={isNewRow}
							hideSelectedOptions={!isNewRow}
							rowIndex={row.index}
							customSyle={DropDownStyles}
							editing={!isNewRow && !editTable.duplicateRow}
						/>;
	  				}
	  				if (row.value === undefined || row.value.length === 0) {
	  					return <Text>Any</Text>;
	  				}
	  				return <Text>{row.value}</Text>;
	  			},
	  		})) as any[];

	  	columns?.push({
	  		Header: '',
	  		accessor: 'delete',
	  		Cell: (row: any) => {
	  			const isCurrentTable =
					!!editTable && editTable.productType === item.productTypeName;

	  			const isEditingCurrentRow =
					(isCurrentTable && !!editTable && editTable.rowId === row.index) ||
					(isCurrentTable &&
						!!editTable &&
						editTable?.productType === item.productTypeName &&
						!!editTable?.duplicateRow &&
						row.index === 0);

	  			const rowIsDisabled =
					!!editTable &&
					((isCurrentTable && editTable.rowId !== row.index) ||
						!isCurrentTable);
	  			return (
	  				<Buttons
	  					isEditing={isEditingCurrentRow || false}
	  					disabled={rowIsDisabled}
	  					onClickSave={handleSubmit(submitForm)}
	  					onClickCancel={closeProductAddRow}
	  					onClickDelete={() => deleteRow(row)}
	  					onClickEdit={() =>
	  						onClickEditOrDuplicate(row, item.productTypeName ?? '', 'edit')
	  					}
	  					onClickDuplicate={() =>
	  						onClickEditOrDuplicate(
	  							row,
	  							item.productTypeName ?? '',
	  							'duplicate'
	  						)
	  					}
	  					isSaving={isSaving}
	  				/>
	  			);
	  		},
	  	});

	  	columns?.push({
	  		accessor: 'id',
	  		show: false,
	  	});
	  	columns?.push({
	  		accessor: 'productTypeName',
	  		show: false,
	  	});
	  	const data = item.products?.map((v) => {
	  		const lineItem: any = {};
	  		v.productSpecs?.forEach((x) => {
	  			lineItem[x.specName!] = x.code;
	  		});

	  		lineItem['id'] = v.id;
	  		lineItem['productTypeName'] = v.productTypeName;

	  		return lineItem;
	  	});
	  	//tuka beses
	  	data?.reverse();
	  	return { columns, data };
	  }
};

export default ProductCatalog;
