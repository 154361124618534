import classNames from 'classnames';
import { Button, FormGroup, Input } from 'components/core';
import { usePTD } from 'context/PTD/PTDContext';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Text } from 'screens/ProductCatalog/components/columns';

interface TableColumnProductNameProps {
	vendorFormMethods: UseFormReturn<any, object>;
	row: any;
	name: string;
	editTable: VendorTable | ProductTable | null;
	setAddProductModal: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			product?: PTDProduct;
		}>
	>;
}

function TableColumnProductName({
	vendorFormMethods,
	row,
	name,
	editTable,
	setAddProductModal,
}: TableColumnProductNameProps) {
	// Get PTD
	const { getPTDProducts } = usePTD();
	const PTDProducts = getPTDProducts();

	if (!!editTable) {
		if (editTable?.rowId === row.index) {
			const product = PTDProducts.find(
				(product) => product.id === (editTable as ProductTable).prodId
			);
			return (
				<Controller
					key={'name'}
					control={vendorFormMethods.control}
					defaultValue={row.value || ''}
					name={name}
					render={({ field, fieldState }) => (
						<FormGroup
							field={field}
							fieldState={fieldState}
							className="mb-0 pb-0"
						>
							<Input
								id="name"
								name={field.name}
								value={field.value}
								onChange={field.onChange}
								error={!!fieldState.error?.message}
								className={classNames(
									'fs-14',
									!!fieldState.error?.message
										? 'custom-input-error'
										: 'custom-input'
								)}
								disabled={true}
								style={{ padding: '7px', marginTop: '5px' }}
							/>
							<Button
								onClick={() => setAddProductModal({ isOpen: true, product })}
								className="btn btn-link fs-12 ps-0 pe-1 pb-1 pt-2"
							>
								Edit Product
							</Button>
						</FormGroup>
					)}
				/>
			);
		}
	}

	return <Text tooltip={row.value}>{row.value}</Text>;
}

export default TableColumnProductName;
