import React from 'react';
import EnhancedTable from 'vna/components/tables/TableMUI';
import { DynamicTableCell, DynamicTableData } from 'vna/types/interfaces';
import Accordion from 'vna/components/accordion/Accordion';
import ScatterChartMUI from 'vna/components/charts/ScatterChartMUI';
import lodash from 'lodash';
import { styled, Tooltip } from '@mui/material';
import { IconHelpCircle } from 'assets/icons';

interface AccordionVendorsCountriesPerProductProps {
    countriesPerProductArray: DynamicTableData[];
    vendorsPerProductArray: DynamicTableData[];
}


function AccordionVendorsCountriesPerProduct({
	countriesPerProductArray, vendorsPerProductArray,
}: AccordionVendorsCountriesPerProductProps) {
	// Merge the two arrays
	const itemsPerCountryArray = countriesPerProductArray.map(item1 => {
		const match = vendorsPerProductArray.find(item2 => item2.product === item1.product);
		return {
			...item1,
			...match
		};
	});

	const groupedData = lodash.map(
		lodash.groupBy(itemsPerCountryArray, (item) => `${item.vendor}-${item.product}`),
		(countryVendorGroup, key) => {
			const products = countryVendorGroup.map((item) => item.product) as string[];
			const vendors = countryVendorGroup.map((item) => item.vendor as number).at(0) || 0;
		 	const countries = countryVendorGroup.map((item) => item.country as number).at(0) || 0;
			return { id: key, z: products, x: vendors, y: countries };
		}
	);

	const itemsPerCountryColumns: readonly DynamicTableCell[] = [
		{
			id: 'product',
			numeric: false,
			disablePadding: false,
			label: 'Product',
		},
		{
			id: 'vendor',
			numeric: false,
			disablePadding: true,
			label: 'Vendor',
		},{
			id: 'country',
			numeric: false,
			disablePadding: false,
			label: 'Country',
		},
	];

	const HelpIcon = styled(IconHelpCircle)(({ theme }) => ({
		width: 20,
		height: 20,
		marginLeft: theme.spacing(1), // Adds some space between text and icon
		color: '#666',
		cursor: 'pointer', // Makes it look clickable
		'&:hover': {
		  color: '#006361', // Change color on hover
		},
	}));

	return (
		<Accordion label="Products - No. Vendors and No. Countries">
			<div className="row gx-4 justify-content-center">
				<div className="col-lg-6 col-md-12">
					<ScatterChartMUI
						series={groupedData}
						tooltipTitle="Products"
						labelX="No. Vendors"
						labelY="No. Countries"
					/>
				</div>

				<div className="col-lg-6 col-md-12 mt-2">
					<EnhancedTable
						rows={itemsPerCountryArray}
						columns={itemsPerCountryColumns}
						chartColumns={{vendor: 'No. Vendor', country: 'No. Country'}}
						toolbarChildren={
							<Tooltip className="me-3" placement="top-end" title={
								<p className="m-2">
									This chart and table display the distribution of vendors and their reach across countries, along with the volume of products supplied.
									<ul>
										<li>
											Points in the top-right indicate vendors with extensive reach across many countries and products, representing key strategic players.
										</li>
										<li>
											Points in the bottom-left represent vendors with limited geographic presence and fewer products, suggesting lower influence or impact.
										</li>
									</ul>
									This visualization helps you assess vendor diversity and critical dependencies.
								</p>
							}>
								<HelpIcon />
							</Tooltip>
						}
					/>
				</div>
			</div>
		</Accordion>
	);
}

export default AccordionVendorsCountriesPerProduct;
