import React, { useContext } from 'react';

interface IState {
	isInitializingPTDVendors: boolean,
	isInitializingPTDVendorsTab: boolean,
	isInitializingPTDVendorTab: boolean,
	isInitializingPTDVendorsSpecs: boolean,
	// Vendor
	getPTDVendors: () => {[x: string]: PTDVendor[]},
	getPTDVendorsTab: () => PTDVendorTab[],
	getPTDVendorTab: () => PTDVendorTypeTab,
	getPTDVendorsSpecs: () => PTDVendorSpecs,
	fetchVendorsForPTDList: (idPTDList: number, signal: AbortSignal) => void,
	fetchVendorsForVendorsTab: (idPTDList: number, signal: AbortSignal) => void,
	fetchDataForVendorTab: (vendorType: number, signal: AbortSignal) => void,
	fetchVendorsSpecs: (id: number, signal: AbortSignal) => void,
	addPTDVendor: (newProduct: PTDVendor, idPTDProduct: number) => void,
	updatePTDVendor: (newProduct: PTDVendor, idPTDProduct: number) => void,
	deletePTDVendor: (idPTDProduct: number, idPTDVendor: number) => void,
}

const initialState: IState = {
	isInitializingPTDVendors: true,
	isInitializingPTDVendorsTab: true,
	isInitializingPTDVendorTab: true,
	isInitializingPTDVendorsSpecs: true,
	// Vendor
	getPTDVendors: () => ({} as {[x: string]: PTDVendor[]}),
	getPTDVendorsTab: () => ([]),
	getPTDVendorTab: () => ({} as PTDVendorTypeTab),
	getPTDVendorsSpecs: () => ({} as PTDVendorSpecs),
	fetchVendorsForPTDList: () => {},
	fetchVendorsForVendorsTab: () => {},
	fetchDataForVendorTab: () => {},
	fetchVendorsSpecs: () => {},
	addPTDVendor: () => {},
	updatePTDVendor: () => {},
	deletePTDVendor: () => {},
};

const PTDVendorsContext = React.createContext(initialState);

function usePTDVendors() {
	const context = useContext(PTDVendorsContext);
	if (context === undefined) {
		throw new Error('PTDContext was used outside the PTDStore');
	}
	return context;
}

export {PTDVendorsContext, usePTDVendors};


